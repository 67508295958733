import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './Errors.scss';
import { 
    Card,
    CardContent,
    Box,
    Button
} from '@mui/material';

import { redirectForm, redirectSource } from '../../helpers/ipayHelper';

/**
 * 
 * @param {*} props 
 * closeBtnText : For Set Close Btn text
 * errorMsg : For Show Error Text
 * @returns 
 */
function ErrorScreen(props) {

    const settingState = useSelector(state => state.settingReducer);

    const onClose = (data) => {

        let params = {
            code : 'ERROR',
            description : data.errorMsg,
        }

        if(data.redirectData!=undefined){
            params = {...params, ...data.redirectData};
        }

        if(settingState['paymentAction']=="WINDOW"){

            params['url'] = data?.redirectTo;
            
            redirectForm(params);
        }
        else{

            redirectSource(params);
        }
    }

    return (
        <Card
            className='center'
            sx={{ 
                minWidth: settingState.minMainScreenWidth,
                height: settingState.mainScreenHeight, 
                width: settingState.mainScreenWidth 
            }}
        >
            <CardContent className='d-flex flex-column height-100p'>
                <div className='d-flex flex-column justify-content-center align-items-center flex-grow-1'>
                    <embed 
                        src={'https://apitest.payu.in/public/assets/images/failure.svg'}
                        style={{width:'50px', height:'50px'}} 
                        className='mb-5'
                    />
                    <p className='text-center'>{props.errorMsg}</p>
                    {
                        (props.closeBtnVisible==undefined) ? <div className='mt-4'>
                            <Button variant="text" className='text-primary' onClick={() => onClose(props)}>{ (props.closeBtnText!=undefined) ? props.closeBtnText : 'Close' }</Button>
                        </div> : ''
                    }
                    
                </div>
            </CardContent>
        </Card>
    )
}

export default ErrorScreen;
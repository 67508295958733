const initialState = {
    productName : '',
    productDescription : '',
    orderId : '',
};

export default (state = initialState, action) => {
    switch (action.type) {

        case 'UPDATE_PRODUCT_INFO':

            const prodData = action.payload;
            return { ...state, ...prodData };

        default:
            return state;
    }
};
const regexForValidation = {
    mobile: "^[6789][0-9]{9}$",
    pan: "^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
    fullname: "^[^\\s]+( [^\\s]+)+$",
    emailId : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    otp : "^[0-9]{1,6}$",
    ipin : "^[0-9]{1,4}$",
    base64 : /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/,
    vpa : "^[a-zA-Z0-9.\-_]{2,49}@[a-zA-Z._]{2,49}$",
}

export default {
    validation : regexForValidation,
    //emailValidation: EmailRegex

}
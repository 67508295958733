const initialSettingState = {
    mainScreenHeight : 642,
    mainScreenWidth : 360,
    minMainScreenWidth : 275,
    mainSectionHeight : 580,
    mainScreenContentBody_HeaderSection : 62,
    bottomViewForPayNowHeight: 148,
    visiblePaymentSectionHeight : 450,
    visiblePaymentSection: true, //default : true; options: true,false
    paymentAction : '', //options: POPUP,WINDOW
    loginScreenVisible : true, //default : true; options: true,false
    paymentScreenVisible : false, // default : false; options: true,false
    cardPaymentScreenVisible : false, // default : false; options: true,false
    walletPaymentScreenVisible: false, // default : false; options: true,false
    netbankingPaymentScreenVisible: false, // default : false; options: true,false
    screenLoader : true, // default : true; options: true,false
    ipayWalletPaymentScreenVisible: false, // default : false; options: true,false
};

export default (state = initialSettingState, action) => {
    switch (action.type) {

        case 'UPDATE_SCREEN_LOADER':

            const screenLoader = action.payload;
            return { ...state, ...screenLoader };

        case 'UPDATE_VISIBLE_PAYMENT_SECTION':

            const userData = action.payload;
            return { ...state, ...userData };

        case 'UPDATE_LOGIN_SCREEN':

            const loginScreen = action.payload;
            return { ...state, ...loginScreen };

        case 'UPDATE_CARD_PAYMENT_SCREEN':

            const cardPaymentScreen = action.payload;
            return { ...state, ...cardPaymentScreen };

        case 'UPDATE_WALLET_PAYMENT_SCREEN':

            const walletPaymentScreen = action.payload;
            return { ...state, ...walletPaymentScreen };

        case 'UPDATE_NETBANKING_PAYMENT_SCREEN':

            const netbankingPaymentScreen = action.payload;
            return { ...state, ...netbankingPaymentScreen };

        case 'UPDATE_IPAY_WALLET_PAYMENT_SCREEN':

            const ipayWalletPaymentScreenVisible = action.payload;
            return { ...state, ...ipayWalletPaymentScreenVisible };

        case 'UPDATE_PAYMENT_ACTIONS':

            const paymentAction = action.payload;
            return { ...state, ...paymentAction };

        default:
            return state;
    }
};
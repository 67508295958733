import React, {useState, useEffect} from 'react'
import './CardPayment.scss';

import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux'
import { SettingAction } from '../../../stores/actions';
import {
    FormControl, 
    InputLabel, 
    CardContent, 
    InputAdornment, 
    OutlinedInput, 
    TextField,
    FormHelperText,
    Input,
    Checkbox,
    Dialog,
    DialogContent,
    Fade
} from '@mui/material';
import Paynow from '../../pay-now/Paynow';
import DummyVisaCardCvv from '../../../assets/dummy_visa_cvv.svg' ;
import DummyAmxCardCvv from '../../../assets/dummy_amx_cvv.svg' ;
import { validateCardNumber } from '../../../helpers/cardTypeHelper'

export default function CardPayment(props) {

    const settingState = useSelector(state => state.settingReducer);
    const themeState = useSelector(state => state.themeReducer);
    const userState = useSelector(state => state.userReducer);
    const dispatch = useDispatch();

    const [cardValidity, setCardValidity] = useState('');
    const [isValidCardValidity, setIsValidCardValidity] = useState(null);
    const [cvvDetailsModalVisible, setCvvDetailsModalVisible] = useState(false);
    const [cardCvv, setCardCvv] = useState('');
    const [cardMaskCvv, setCardMaskCvv] = useState('');
    const [cardType, setCardType] = useState('');
    const [cardNumber, setCardNumber] = useState('');

    const backToMainPaymentScreen = () => {

        dispatch(SettingAction.updateCardPaymentScreen(false));

    }

    const onChangeCardNumber = async (event) => {

        let text = event.target.value;

        text = text.replace(/[^0-9]+/g, "");

        //console.log('test :',text.length);

        if(text.length==0){
            setCardType('');
            setCardNumber('');
            return false;
        }

        if(cardType['cardNumberLength']!=undefined && cardType['cardNumberLength']!=''){

            if(text.length > cardType['cardNumberLength']){
                return false;
            }
        }

        let getCardDetails = await validateCardNumber(text);

        //console.log(getCardDetails);

        setCardType(getCardDetails);

        if(getCardDetails['cardBlock']!=undefined && getCardDetails['cardBlock'].length > 0){

            let wholeNumber = text;
            let makeNumberSplit = [];

            for(let i=0; i < getCardDetails['cardBlock'].length; i++){

                //console.log('blockSize :',i,getCardDetails['cardBlock'][i]);

                let getNumber = wholeNumber.substring(0,getCardDetails['cardBlock'][i]);

                makeNumberSplit.push(getNumber);
                
                wholeNumber = wholeNumber.slice(getCardDetails['cardBlock'][i]);
                
                //console.log('after slice',wholeNumber);
            }

            //console.log('finalOutput',makeNumberSplit);

            makeNumberSplit = makeNumberSplit.filter(item => item);

            text = makeNumberSplit.join(" ");

            //console.log('finalOutput111',text);
        }

        setCardNumber(text);
    }

    const onChangeCardValidity = (event) => {

        let text = event.target.value;
        let oldText = cardValidity;

        //console.log('comming :',text.length, 'old :',oldText);
        //return false;
        if(text.includes("/")){

            let splitText = text.split("/");
            
            if(text.length==4){
                //console.log('comming :',text.length, 'old :',(splitText[0]).trim());
                //return false;
                setCardValidity((splitText[0]).trim());
                return false;
            }

            
            text = (splitText[1]).trim();
        }

        let filteredValue = text.replace(/[^0-9]+/g, "");

        if(filteredValue > 1 && filteredValue <= 9 && !oldText.includes("/")){
            filteredValue = "0"+filteredValue;
        }

        if(((filteredValue).toString()).length==2 && !oldText.includes("/")){
            filteredValue = filteredValue+" / ";
        }

        //console.log('passed :',filteredValue);

        let finalOutput = filteredValue;

        if(oldText.includes("/")){

            let splitTextFinal = oldText.split("/");
            let getMonth = (splitTextFinal[0]).trim();
            let getYear = filteredValue;

            finalOutput = getMonth +" / "+ getYear;

            if((splitTextFinal[1]).trim() =='' && filteredValue =='' ){
                finalOutput = getMonth;
            }

            let currentDate = new Date();
            let getCurrentMonth = currentDate.getMonth() + 1;
            let getCurrentYear = currentDate.getFullYear();
            let getLastTwoChar = getCurrentYear.toString();
            getLastTwoChar = getLastTwoChar.slice(-2);

            //Check Year
            if(parseInt(getYear) < parseInt(getLastTwoChar)){

                setIsValidCardValidity(false);
            }
            else{

                if(parseInt(getYear) == parseInt(getLastTwoChar)){

                    //Check Month
                    if(parseInt(getMonth) < getCurrentMonth){
                        setIsValidCardValidity(false);
                    }
                    else{
                        setIsValidCardValidity(true);
                    }
                }
                else{
                    setIsValidCardValidity(true);
                }
                
            }
        }

        setCardValidity(finalOutput);
    }

    const onChangeCardCvv = (event) => {

        let text = event.target.value;

        let filteredValue = text.replace(/[^0-9]+/g, "");

        let dotValue = "•";

        if(filteredValue!=''){

            setCardMaskCvv((prevState) => {
                let oldDt = prevState+dotValue ;
                return oldDt;
            });

            setCardCvv((prevState) => {
                let oldVl = (prevState).toString() + (filteredValue).toString();
                return oldVl;
            }); 

        }
        else{

            setCardMaskCvv((prevState) => {
                let oldDt = prevState.slice(0, -1); 
                return oldDt;
            });

            setCardCvv((prevState) => {
                let oldVl = prevState.slice(0, -1); 
                return oldVl;
            }); 
        }

    }

    const cardPaymentAction = () => {

        console.log("cardCvv :", cardCvv);
    }

    const closeCvvDetailsModalModal = (closeReason) => {

        setCvvDetailsModalVisible(false);
    }

    const openCvvDetailsModalModal = () => {

        setCvvDetailsModalVisible(true);
    }

    return (
        
        <div className='card-payment-main-section'>
            <CardContent 
                className={`body-container`}
                style={{
                    height: (settingState.visiblePaymentSection)? settingState.visiblePaymentSectionHeight+'px' : settingState.mainSectionHeight+'px',
                }}
            >
                <div className='d-flex flex-row align-items-center mb-4'> 
                    <div className='mark-close pointer' onClick={() => backToMainPaymentScreen()}>
                        <i className="material-icons ">navigate_before</i>
                    </div> 
                    <p className='font-size-14 font-weight-600 text-dark mb-2'>Cards</p>
                </div>
                <div className='mb-3'>
                    <FormControl variant="outlined" fullWidth>
                        <TextField 
                            type={'tel'}
                            label="Card Number" 
                            variant="outlined" 
                            className='ipayInput' 
                            InputProps={{
                                autoCapitalize : 'off',
                                autoCorrect : 'off',
                                spellCheck : 'off',
                                endAdornment : (
                                    <InputAdornment position="end">
                                        <img src={cardType['img']} />
                                    </InputAdornment>
                                )
                            }}
                            value={cardNumber}
                            onChange={(event) => onChangeCardNumber(event)}
                            error={(cardType['status']!=undefined && cardType['status']=='INVALID') ? true : false }
                            helperText={cardType['desc']}
                        />
                    </FormControl>
                </div>
                <div className='d-flex flex-row mb-3'>
                    <div className='width-50p'>
                        <FormControl variant="outlined" fullWidth>
                            <TextField 
                                label="Expiry" 
                                variant="outlined" 
                                className='ipayInput' 
                                placeholder='MM / YY'
                                inputProps={{
                                    maxLength : 7,
                                    autoCapitalize : 'off',
                                    autoCorrect : 'off',
                                    spellCheck : 'off', 
                                }}
                                value={cardValidity}
                                onChange={(event) => onChangeCardValidity(event) }
                                error={!isValidCardValidity && isValidCardValidity!=null}
                                helperText={(cardValidity!='' && !isValidCardValidity) ? 'Invalid Card Expiry' : ''}
                            />
                        </FormControl>
                        
                    </div>
                    <div className='width-50p ms-2'>
                        <FormControl fullWidth variant="outlined" className='ipayInput'>
                            <InputLabel>CVV</InputLabel>
                            <OutlinedInput
                                type={'text'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <div className='mark-close pointer' onClick={() => openCvvDetailsModalModal()}>
                                            <i className="material-symbols-outlined ">info</i>
                                        </div> 
                                    </InputAdornment>
                                }
                                label="CVV"
                                inputProps={{
                                    maxLength : 3,
                                    autoCapitalize : 'off',
                                    autoCorrect : 'off',
                                    spellCheck : 'off',
                                    inputMode:'numeric'
                                }}
                                value={cardMaskCvv}
                                onChange={(event) => onChangeCardCvv(event)}
                                style={{fontWeight: 300}}
                            />
                            {/* <FormHelperText id="outlined-weight-helper-text">Weight</FormHelperText> */}
                        </FormControl>
                    </div>
                </div>
                <div className='mb-3'>
                    <FormControl variant="outlined" fullWidth>
                        <TextField 
                            label="Name on Card" 
                            variant="outlined" 
                            className='ipayInput' 
                            inputProps={{
                                maxLength : 100,
                                autoCapitalize : 'off',
                                autoCorrect : 'off',
                                spellCheck : 'off',      
                            }}
                            
                        />
                    </FormControl>
                </div>
                <div className='d-flex flex-row align-items-center mb-4'>
                    <Checkbox style={{ color : themeState.brandColor}} />
                    <span className='font-size-14 font-weight-500 text-dark'>Secure this card for faster checkout</span>
                </div>
                <p className='font-size-10 font-weight-400 text-lightdark'>*By completing your order, you will checkout with InstantPay Pvt Ltd as the merchant of record and agree with the <a href={process.env.REACT_APP_WEBSITE_TERMS} className="text-primary" target="_blank">terms and conditions.</a> </p>

            </CardContent>

            {/** Paynow Section */}
            <Paynow actionFired={() => cardPaymentAction()} />

            {/** CVV Details Modal */}
            <Dialog
                className='ipayModalBottom'
                open={cvvDetailsModalVisible}
                keepMounted
                onClose={(event,reason) => closeCvvDetailsModalModal(reason) }
                container={() => document.getElementById('mainScreenContent')}
                TransitionComponent={Fade}
                disableEscapeKeyDown={true}
            >
                <DialogContent>
                    <div>
                        <div className='d-flex flex-row justify-content-between align-items-center mb-4'>
                            <p className='font-size-16 font-weight-600 text-dark mb-0'>What is CVV?</p>
                            <div className='mark-close pointer text-lightdark' onClick={() => closeCvvDetailsModalModal('')}>
                                <i className="material-icons">close</i>
                            </div>
                        </div>
                        <p className='text-lightdark font-size-12 font-weight-500 mb-4'>3 or 4 Digit Security code found on Front or Back of your credit and debit card.</p>
                        <p className='font-size-14 font-weight-600 text-dark mb-2'>Visa, Master Card & Discover</p>
                        <img src={DummyVisaCardCvv} className="width-100p mb-3" />
                        <p className='font-size-14 font-weight-600 text-dark mb-2'>American Express card</p>
                        <img src={DummyAmxCardCvv} className="width-100p mb-3" />
                    </div>

                </DialogContent>
            </Dialog>

        </div>
    )
}

/**
 * 
 * @param {object} data
 * @returns 
 */
const updatePaymentMethod = (data) => {

    return {
        type: 'UPDATE_PAYMENT_METHODS',
        payload: {
            paymentMethods : data
        }
    }
}

/**
 * 
 * @param {object} data
 * @returns 
 */
const updateSelectedPaymentMethod = (data) => {

    return {
        type: 'UPDATE_SELECTED_PAYMENT_METHOD',
        payload: {
            selectedPaymentMethod : data
        }
    }
}

export default {
    updatePaymentMethod,
    updateSelectedPaymentMethod
}
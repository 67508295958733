const initialThemeState = {
    textColor : '',
    bgColor : '',
    brandColor : '',
};

export default (state = initialThemeState, action) => {
    switch (action.type) {

        case 'UPDATE_TEXT_COLOR':

            const textColor = action.payload;
            return { ...state, ...textColor };

        case 'UPDATE_BG_COLOR':

            const bgColor = action.payload;
            return { ...state, ...bgColor };

        case 'UPDATE_BRAND_COLOR':

            const brandColor = action.payload;
            return { ...state, ...brandColor };

        default:
            return state;
    }
};
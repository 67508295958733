/**
 * 
 * @param {Object} data //{name : '', description:'' }
 * @returns 
 */
const updateProductInfo = (data) => {

    let payloadData = {};

    if(data.name!=undefined && data.name!=''){
        payloadData['productName'] = data.name;
    }

    if(data.description!=undefined && data.description!=''){
        payloadData['productDescription'] = data.description;
    }

    if(data.orderId!=undefined && data.orderId!=''){
        payloadData['orderId'] = data.orderId;
    }

    return {
        type: 'UPDATE_PRODUCT_INFO',
        payload: payloadData
    }
}

export default {
    updateProductInfo,
}
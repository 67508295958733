import React,{ useEffect, useState } from 'react'
import './NetbankingPayment.scss';
import { useDispatch, useSelector } from 'react-redux'
import { SettingAction } from '../../../stores/actions';
import {
    FormControl, 
    InputLabel, 
    CardContent, 
    InputAdornment, 
    OutlinedInput,
    Radio,
    RadioGroup,
    FormControlLabel,
    TextField,
    FormHelperText,
    Input,
    Checkbox,
    Dialog,
    DialogContent,
    Fade
} from '@mui/material';
import Paynow from '../../pay-now/Paynow';

const banks = [
    {
        name : 'State Bank of India',
        icon : 'sbi.svg',
        code : 'SBI',
    },
    {
        name : 'HDFC Bank',
        icon : 'hdfc.svg',
        code : 'HDFC',
    },
    {
        name : 'ICICI Bank',
        icon : 'icici.svg',
        code : 'ICICI',
    },
    {
        name : 'Axis Bank',
        icon : 'axis.svg',
        code : 'AXIS',
    },
    {
        name : 'Kotak Mahindra Bank',
        icon : 'kotak.svg',
        code : 'KOTAK',
    },
    {
        name : 'Canara Bank',
        icon : 'canara.svg',
        code : 'CANARA',
    },
    
]

export default function NetbankingPayment(props) {

    const settingState = useSelector(state => state.settingReducer);
    const themeState = useSelector(state => state.themeReducer);
    const userState = useSelector(state => state.userReducer);
    const dispatch = useDispatch();

    const [searchBank, setSearchBank] = useState('');
    const [bankList, setBankList] = useState([]);
    const [filteredBankList, setFilteredBankList] = useState([]);
    const [selectedBank, setSelectedBank] = useState('');
    const [firedErrorOnButton, setFiredErrorOnButton] = useState(false);

    useEffect(() => {

        setBankList(banks);
        setFilteredBankList(banks);

    },[]);

    const backToMainPaymentScreen = () => {

        dispatch(SettingAction.updateNetbankingPaymentScreen(false));

    }

    const onChangeSearchText = (event) => {

        let text = event.target.value;

        setSearchBank(text);

        if(text==''){

            setFilteredBankList(bankList);

            return false;
        }

        let result = bankList.filter(item => {
            let convertItemToSmall = (item.name).toLowerCase();
            return convertItemToSmall.includes(text.toLowerCase());
        });

        setFilteredBankList(result);
    }

    const onSelectedBank = (item,index) => {

        item['selectedIndex'] = index;

        setSelectedBank(item);
    }

    const netbankingPaymentAction = () => {

        setFiredErrorOnButton(false);

        if(selectedBank!=''){

            alert('bank payment done successfully');
            return false;
        }
        else{
            setFiredErrorOnButton(true);

            setTimeout(() => {
                setFiredErrorOnButton(false);
            },500);
        }
    }

    return (
        <div className='netbanking-payment-main-section'>
            <CardContent 
                className={`body-container`}
                style={{
                    height: (settingState.visiblePaymentSection)? settingState.visiblePaymentSectionHeight+'px' : settingState.mainSectionHeight+'px',
                }}
            >
                <div className='d-flex flex-row align-items-center mb-4'> 
                    <div className='mark-close pointer' onClick={() => backToMainPaymentScreen()}>
                        <i className="material-icons ">navigate_before</i>
                    </div> 
                    <p className='font-size-14 font-weight-600 text-dark mb-2'>Net Banking</p>
                </div>
                <div className='d-flex flex-row justify-content-center align-items-center mb-4'>
                    <FormControl size='small' fullWidth variant="outlined" className='ipayInput'>
                        <InputLabel>Choose or search for a bank</InputLabel>
                        <OutlinedInput
                            size='small'
                            endAdornment={
                                <InputAdornment position="end">
                                    <div className='mark-close pointer'>
                                        <i className="material-symbols-outlined font-size-18">search</i>
                                    </div>
                                </InputAdornment>
                            }
                            label="Choose or search for a bank"
                            value={searchBank}
                            onChange={(event) => onChangeSearchText(event)}

                        />
                    </FormControl>
                </div>
                <div className='bank-container'>
                    <RadioGroup
                        defaultValue=""
                        name="bank-buttons-group"
                    >
                    {
                        filteredBankList.map((item, index) => (

                            <div className='d-flex flex-row mb-2 show-bank pointer' key={index} onClick={() => onSelectedBank(item,index)}>
                                <div className='show-bank-item px-2 py-2 width-100p'>
                                    <img src={require(`../../../assets/bank/${item['icon']}`)} style={{width:"30px", height:"30px"}}/>
                                    <span className='font-size-14 font-weight-500 text-dark ms-2'>{item['name']}</span>
                                </div>
                                <FormControlLabel checked={(item['id'] == selectedBank['id'] && index == selectedBank['selectedIndex']) ? true : false } value={item['id']} control={<Radio style={{color: themeState.brandColor}} />} className={`ipayRadio m-0 ${(item['id'] == selectedBank['id'] && index == selectedBank['selectedIndex']) ? '' : 'hidden'}`}  />
                            </div>
                        ))
                    }
                    </RadioGroup>
                </div>


            </CardContent>

            {/** Paynow Section */}
            <Paynow actionFired={() => netbankingPaymentAction()} extraClassName={(firedErrorOnButton) ? 'got-error' : '' } />

        </div>
    )
}

import { convertAsNeed } from "../../helpers/dateTimeHelper"

/**
 * Possible Action Name : 
 *  1. PaymentRetry : For Retry of payment and please set componentName.
 *  2. RetryWithRedirect : Work on Both Retry and Redirect and please set componentName.
 *  3. RedirectToSource : For Redirect to endpoint
 * @param {object} data // status, data
 * @returns 
 */
const showPaymentConfirmation = (data) => {

    let defaultData = {
        visible : false,
        status : '',
        description : '',
        componentName : '',
        actionName : '',
        paymentData : {
            paymentMode : '',
            paymentId : '',
            paymentCreated : '',
        },
        extraData : '',
        allowRawRedirect : false,
        redirectToSource : '',
        redirectToSourceData : {},
    }

    let isClear = false;

    if(data.clear!=undefined && data.clear){
        isClear = true;

        defaultData['visible'] = false;
    }
    else{
        isClear = false;
    }

    if(!isClear){

        defaultData['visible'] = true;

        defaultData['status'] = data.status;

        if(data.description!=undefined){
            defaultData['description'] = data.description;
        }

        if(data.componentName!=undefined){
            defaultData['componentName'] = data.componentName;
        }

        if(data.actionName!=undefined){
            defaultData['actionName'] = data.actionName;
        }

        if(data.paymentData!=undefined && data.paymentData.paymentMode!=undefined){
            defaultData['paymentData']['paymentMode'] = data.paymentData.paymentMode;
        }

        if(data.paymentData!=undefined && data.paymentData.paymentId!=undefined){
            defaultData['paymentData']['paymentId'] = data.paymentData.paymentId;
        }

        if(data.paymentData!=undefined && data.paymentData.paymentCreated!=undefined){

            let getCovDate = convertAsNeed(data.paymentData.paymentCreated,'M d, Y | h:i A','Y-m-d H:i:s');
    
            defaultData['paymentData']['paymentCreated'] = getCovDate;
        }

        if(data.extraData!=undefined){
            defaultData['extraData'] = data.extraData;
        }

        if(data.allowRawRedirect!=undefined && data.allowRawRedirect!=''){
            defaultData['allowRawRedirect'] = data.allowRawRedirect;
        }

        if(data.redirectToSource!=undefined && data.redirectToSource!=''){
            defaultData['redirectToSource'] = data.redirectToSource;
        }

        if(data.redirectToSourceData!=undefined){
            defaultData['redirectToSourceData'] = data.redirectToSourceData;
        }
    }
    

    return {
        type: 'UPDATE_PAYMENT_CONFIRMATION',
        payload: defaultData
    }
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
const paymentRetrySetup = (data) => {

    let defaultData = {
        isRetryPayment : false,
        retryConfig : {
            actionName : '',
            componentName : '',
        },
    }

    let isClear = false;

    if(data.clear!=undefined && data.clear){
        isClear = true;

        defaultData['isRetryPayment'] = false;
    }
    else{
        isClear = false;

        if(data.actionName!=undefined && data.actionName!=''){
            defaultData['retryConfig']['actionName'] = data.actionName;
        }

        if(data.componentName!=undefined && data.componentName!=''){
            defaultData['retryConfig']['componentName'] = data.componentName;
        }
        else{
            console.error('Component name cannot be empty');
            return false;
        }

        defaultData['isRetryPayment'] = true;
    }

    return {
        type: 'UPDATE_PAYMENT_RETRY',
        payload: defaultData
    }
}

export default {
    showPaymentConfirmation,
    paymentRetrySetup
}
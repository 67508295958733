import { Routes, Route, Navigate } from 'react-router-dom';
import WindowHome from './pages/windowHome/home';
import PopupHome from './pages/popupHome/home';
import NotFound from './pages/notfound';
import ErrorPage from './pages/error/errorPage';

const App = () => {
    return (
        <>
            <Routes>
                {/* <Route path="/" element={<Navigate to="/public" replace />} /> */}
                <Route path="/public/embedded/:sessionId" element={<WindowHome />} />
                <Route path="/public/self/:sessionId" element={<PopupHome />} />
                <Route path='/public/error/:errorData' element={ <ErrorPage /> } />
                <Route path="*" element={<NotFound/>} />
            </Routes>
        </>
    );
};
   
export default App;
import _ from 'lodash';
import { getLocation, setCookie } from '../helpers/ipayHelper';
import { notify } from '../helpers/ipayHelper';

/**
 * Options : {
 *      method : POST,
 *      hostname : '', //Based on ENV
 *      debug : 0 //0,1
 *      overrideEndpoint : '' , //Overider endpoint to this url
 *      headers : {} // Extra Headers to pass
 *      credentials : {
 *          clientId : '',
 *          clientSecret : '',
 *      },
 *      setLocation : false, default : false; opt: true,false
 *      iPinConfig = {
            allow: 0,
            iPin: '',
            userId: '',
        },
        downloadConfig = {
            allow: 0,
            fileName : '',eg : file.pdf
            type : 'BLOB', //BASE64,BLOB,URL ; Default:BLOB
        },
        allowFormData : {
            allow: 0,
            content: '', //formdata
        },
        setExternalRef : false, default : false; opt: true,false
        timeout : 30 // deafult 30ms
        getResponseHeader : false, default : false; opt: true,false
 * }
 * data : Body
 */
export const apiRequest = async (options, data) => {

    let isPost = true;

    if (_.has(options, 'method')) {
        if (options.method == "GET") {
            isPost = false;
        }
    }
    else {
        isPost = false;
    }

    let hostname = process.env.REACT_APP_API_PATH;
    let endPoint = '';
    if (_.has(options, 'hostname')) {
        hostname = options.hostname;
    }

    endPoint = hostname + options.path;

    if (_.has(options, 'overrideEndpoint')) {
        endPoint = options.overrideEndpoint;
    }

    if (_.has(options, 'setLocation') && options['setLocation']) {

        let location = await getLocation().catch(err => {

            notify({
                msg: err
            });

            return false;
        });

        data['latitude'] = location['latitude'];
        data['longitude'] = location['longitude'];
    }

    //iPin Config
    let iPinConfig = {
        allow: 0,
        iPin: '',
        userId: '',
    }

    if (_.has(options, 'iPinConfig') && options.iPinConfig.allow) {
        iPinConfig.allow = options.iPinConfig.allow;

        if (!_.has(options.iPinConfig, 'userId') && options.iPinConfig.userId == '') {
            console.error("userId is missing in iPinConfig");
            return false;
        }

        if (_.has(options.iPinConfig, 'iPin') && options.iPinConfig.iPin != '' && options.iPinConfig.iPin != null) {

            
        }
    }

    let isResponseHeader = false;

    if (_.has(options, 'getResponseHeader') && options['getResponseHeader']) {
        isResponseHeader = true;
    }

    let defaultTimeout = 30;

    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'No-Store',
        'X-Ipay-Request': 'PORTAL',
        'X-Requested-With': 'XMLHttpRequest', 
    };

    /* if (_.has(options, 'credentials') && options['credentials']['clientId']) {
        headers['X-App-Id'] = options['credentials']['clientId'];
    } */

    if (_.has(options, 'credentials') && options['credentials']['clientSecret']) {
        headers['X-Access-Token'] = options['credentials']['clientSecret'];
    }

    let requestOptions = {
        method: (isPost) ? options.method : 'GET',
        headers: headers,
        body: (isPost) ? JSON.stringify(data) : null,
        signal: Timeout(defaultTimeout).signal,
        //withCredentials: true,
        //credentials: 'include'
    };

    if (isResponseHeader) {
        requestOptions['credentials'] = 'include';
    }

    return new Promise((resolve, reject) => {

        fetch(endPoint, requestOptions)
        .then(response => {

            if(isResponseHeader){

                let headers = {};
                for(let entry of response.headers.entries()) {
                    headers[entry[0]] = entry[1];
                }

                return {
                    header : headers,
                    bodys : response.text()
                }
            }
            else{
                return response.text();
            }
            
        })
        .then(async result => {

            if(isResponseHeader){

                let getBody = await result.bodys;

                let headers = result.header;

                result = JSON.parse(getBody);

                result['headers'] = headers;

                return resolve(result);
            }
            else{

                result = JSON.parse(result);

                return resolve(result);
            }
        })
        .catch(error => {
            return reject(error);
        });
    })
}



export const Timeout = (time) => {
	let controller = new AbortController();
	setTimeout(() => controller.abort(), time * 1000);
	return controller;
};
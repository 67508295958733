import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { 
    Card,
    CardContent,
    Box
} from '@mui/material';
import Login from './login/Login';
import UserDetails from './user-details/UserDetails';
import { SettingAction } from '../stores/actions'
import PaymentMethod from './payment-method/PaymentMethod';
import CardPayment from './payment-method/card-payment/CardPayment';
import WalletPayment from './payment-method/wallet-payment/WalletPayment';
import NetbankingPayment from './payment-method/netbanking-payment/NetbankingPayment';
import IpayWalletPayment from './payment-method/ipay-wallet/IpayWalletPayment';
import { ToastContainer, toast } from 'react-toastify';
import PaymentConfirmation from './payment-confirmation/PaymentConfirmation';

function MainScreen(props) {

    const settingState = useSelector(state => state.settingReducer);
    const themeState = useSelector(state => state.themeReducer);
    const userState = useSelector(state => state.userReducer);
    const paymentConfirmationState = useSelector(state => state.paymentConfirmationReducer);
    const dispatch = useDispatch();

    const [paymentConfirmationClassName, setPaymentConfirmationClassName] = useState('');

    const showLoginScreen = () => {
        dispatch(SettingAction.updateLoginScreen(true));
    }

    useEffect(() => {

        if(paymentConfirmationState.visible){
            setPaymentConfirmationClassName('showPaymentConfirmationSection');
        }
        else{
            setPaymentConfirmationClassName('');
        }

    },[paymentConfirmationState.visible]);

    return (
        <div 
            id='mainScreenContent' 
            style={{
                border:  (settingState.paymentAction=='POPUP') ? 'none' : '1px solid #BEBEBE',
                opacity: (settingState.paymentAction=='WINDOW') ? '1' : '0',
                overflow:"hidden"
            }}
            className={`${(settingState.paymentAction=='POPUP') ? 'ubharja' : ''} `}
        >   
            
            <Box
                sx={{
                    position: "relative",
                }}
            >
                <ToastContainer 
                    className="customNotify"
                    position={"top-center"}
                    draggable={false}
                />
            </Box>
            
            <Card 
                id='mainScreenContentBody' 
                sx={{ 
                    minWidth: settingState.minMainScreenWidth,
                    height: settingState.mainScreenHeight, 
                    width: settingState.mainScreenWidth,
                    position:'relative'
                }}
            >
                <div className='paymentBodySection'>
                    <Box
                        id='header-section'
                        className='header-section'
                        style={{
                            backgroundColor: themeState.bgColor,
                            color: themeState.textColor,
                            height : settingState.mainScreenContentBody_HeaderSection,
                            padding: 0,
                        }}
                    >
                        <UserDetails />
                    </Box>

                    <Box
                        id='body-section'
                        className='body-section'
                        style={{
                            backgroundColor: '#F7F7F7',
                            height: `calc(100% - ${settingState.mainScreenContentBody_HeaderSection}px)`,
                            //border: '1px solid red',
                            paddingBottom : settingState.bottomViewForPayNowHeight,
                            overflowY: 'scroll',
                        }}
                    >
                        {//For Login Screen
                            settingState.loginScreenVisible ? <Login /> : ''
                        }

                        {//For Payment Screen
                            settingState.paymentScreenVisible ? <PaymentMethod /> : ''
                        }

                        {//For Card-Payment Screen
                            settingState.cardPaymentScreenVisible ? <CardPayment /> : ''
                        }

                        {//For Wallet-Payment Screen
                            settingState.walletPaymentScreenVisible ? <WalletPayment /> : ''
                        }

                        {//For Netbanking-Payment Screen
                            settingState.netbankingPaymentScreenVisible ? <NetbankingPayment /> : ''
                        }

                        {//For IpayWallet-Payment Screen
                            settingState.ipayWalletPaymentScreenVisible ? <IpayWalletPayment /> : ''
                        }

                    </Box>
                </div>
                
                {
                    (paymentConfirmationState.visible) ?  
                        <div className={`paymentConfirmationSection ${paymentConfirmationClassName}`}>
                            <PaymentConfirmation />
                        </div>  : ''
                }
                
            </Card>

            

        </div>
        
    )
}

export default MainScreen;
/**
 * 
 * @param {object} data
 * @returns 
 */
const updateConfig = (data) => {

    return {
        type: 'UPDATE_CONFIG',
        payload: data
    }
}

export default {
    updateConfig
}
import React, {useEffect, useState} from 'react'
import './UserDetails.scss';
import Card from '@mui/material/Card';
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Slide } from '@mui/material';
import { UserAction,SettingAction } from '../../stores/actions';
import { apiRequest } from '../../network/ApiClient';
import { notify, redirectForm, redirectSource } from '../../helpers/ipayHelper';
import _ from 'lodash';

function UserDetails(props) {

    const userState = useSelector((state) => state.userReducer);
    const settingState = useSelector(state => state.settingReducer);
    const configState = useSelector(state => state.configReducer);
    const productState = useSelector(state => state.productReducer);
    const dispatch = useDispatch();

    const [closeDrawerVisible, setCloseDrawerVisible] = useState(false);
    

    useEffect(() => {

        //console.log('user',props);

        

        return() => {

        }

    },[]);

    const openCloseModal = () => {

        setCloseDrawerVisible(true);
    }

    const closePayment =  async () => {

        setCloseDrawerVisible(false);

        let params = {
            code : 'CANCALLED',
            description : 'Payment Cancalled by User',
            ipayRequestId : configState.requestId,
        }

        if(configState.extraData!=undefined && !_.isEmpty(configState.extraData)){
            params['extraData'] = configState.extraData;
        }

        if(settingState['paymentAction']=="WINDOW"){

            params['url'] = configState.failedUrl;
            
            redirectForm(params);
        }
        else{

            redirectSource(params);
        }
        
    }

    return (
        <div className='contact-details-section'>
            <div className='d-flex flex-row align-items-center p-3'>
                <div className='business-logo'>
                    <img crossOrigin='anonymous' src={userState.logo} style={{width:'33px', height:'33px'}} />
                </div>
                <div className='business-details d-flex flex-column flex-grow-1 pl-2'>
                    <span className='font-size-14 font-weight-600 business-details-name'>{ userState.displayName }</span>
                    <span className='font-size-10 font-weight-400'>{ (productState.orderId!=undefined &&  productState.orderId!=null && productState.orderId!='') ?  'Order # ' + productState.orderId  : '' }</span>
                </div>
                <div className='mark-close'>
                    <i className="material-icons pointer" onClick={() => openCloseModal()}>close</i>
                </div>
            </div>

            <Dialog
                className='ipayModal'
                open={closeDrawerVisible}
                keepMounted
                onClose={() => setCloseDrawerVisible(false) }
                aria-describedby="alert-dialog-slide-description"
                container={() => document.getElementById('mainScreenContent')}
                TransitionComponent={Slide}
            >
                <DialogTitle>{"Cancel payment"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Your payment is ongoing. Are you sure you want to cancel the payment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='text-danger' onClick={() => closePayment()} >Yes, cancel</Button>
                    <Button className='text-dark' onClick={() => setCloseDrawerVisible(false)} >No</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default UserDetails;

import React, { useEffect, useState } from 'react'
import './WalletPayment.scss';
import { useDispatch, useSelector } from 'react-redux'
import { SettingAction } from '../../../stores/actions';
import {
    FormControl, 
    InputLabel, 
    CardContent, 
    InputAdornment, 
    OutlinedInput, 
    Radio,
    RadioGroup,
    FormControlLabel,
    TextField,
    FormHelperText,
    Input,
    Checkbox,
    Dialog,
    DialogContent,
    Fade
} from '@mui/material';
import Paynow from '../../pay-now/Paynow';

const walletList = [
    {
        name : 'Paytm',
        icon : 'paytm.svg',
        id : 1,
    },
    {
        name : 'PhonePe',
        icon : 'phonepe.svg',
        id : 2,
    },
    {
        name : 'Amazon Pay',
        icon : 'amazon.svg',
        id : 3,
    },
    {
        name : 'MobiKwik',
        icon : 'mobikwik.svg',
        id : 4,
    },
    {
        name : 'FreeCharge',
        icon : 'freecharge.svg',
        id : 5,
    },
    {
        name : 'Ola Money',
        icon : 'ola.svg',
        id : 6,
    },
    {
        name : 'Airtel Payment Bank',
        icon : 'airtel.svg',
        id : 7,
    },
    {
        name : 'Jio Wallet',
        icon : 'jio.svg',
        id : 8,
    },
]

export default function WalletPayment(props) {

    const settingState = useSelector(state => state.settingReducer);
    const themeState = useSelector(state => state.themeReducer);
    const userState = useSelector(state => state.userReducer);
    const dispatch = useDispatch();

    const [searchWallet, setSearchWallet] = useState('');
    const [walletPaymentList, setWalletPaymentList] = useState([]);
    const [filteredWalletPaymentList, setFilteredWalletPaymentList] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState('');
    const [firedErrorOnButton, setFiredErrorOnButton] = useState(false);

    useEffect(() => {

        setWalletPaymentList(walletList);
        setFilteredWalletPaymentList(walletList);

    },[]);

    const backToMainPaymentScreen = () => {

        dispatch(SettingAction.updateWalletPaymentScreen(false));

    }

    const onChangeSearchText = (event) => {

        let text = event.target.value;

        setSearchWallet(text);

        if(text==''){

            setFilteredWalletPaymentList(walletPaymentList);

            return false;
        }

        let result = walletPaymentList.filter(item => {
            let convertItemToSmall = (item.name).toLowerCase();
            return convertItemToSmall.includes(text.toLowerCase());
        });

        setFilteredWalletPaymentList(result);
    }

    const onSelectedWallet = (item,index) => {

        item['selectedIndex'] = index;

        setSelectedWallet(item);
    }

    const walletPaymentAction = () => {

        setFiredErrorOnButton(false);

        if(selectedWallet!=''){

            alert('wallet payment done successfully');
            return false;
        }
        else{
            setFiredErrorOnButton(true);

            setTimeout(() => {
                setFiredErrorOnButton(false);
            },500);
        }
    }

    return (
        <div className='wallet-payment-main-section'>
            <CardContent 
                className={`body-container`}
                style={{
                    height: (settingState.visiblePaymentSection)? settingState.visiblePaymentSectionHeight+'px' : settingState.mainSectionHeight+'px',
                }}
            >
                <div className='d-flex flex-row align-items-center mb-4'> 
                    <div className='mark-close pointer' onClick={() => backToMainPaymentScreen()}>
                        <i className="material-icons ">navigate_before</i>
                    </div> 
                    <p className='font-size-14 font-weight-600 text-dark mb-2'>Wallet</p>
                </div>
                <div className='d-flex flex-row justify-content-center align-items-center mb-4'>
                    <FormControl size='small' fullWidth variant="outlined" className='ipayInput'>
                        <InputLabel>Select a different Wallet</InputLabel>
                        <OutlinedInput
                            size='small'
                            endAdornment={
                                <InputAdornment position="end">
                                    <div className='mark-close pointer'>
                                        <i className="material-symbols-outlined font-size-18">search</i>
                                    </div>
                                </InputAdornment>
                            }
                            label="Select a different Wallet"
                            value={searchWallet}
                            onChange={(event) => onChangeSearchText(event)}

                        />
                    </FormControl>
                </div>
                <div className='wallets-container'>
                    <RadioGroup
                        defaultValue=""
                        name="wallet-buttons-group"
                    >
                    {
                        filteredWalletPaymentList.map((item, index) => (

                            <div className='d-flex flex-row mb-2 show-wallets pointer' key={index} onClick={() => onSelectedWallet(item,index)}>
                                <div className='show-wallets-item px-2 py-2 width-100p'>
                                    <img src={require(`../../../assets/wallet/${item['icon']}`)} style={{width:"30px", height:"30px"}}/>
                                    <span className='font-size-14 font-weight-500 text-dark ms-2'>{item['name']}</span>
                                </div>
                                <FormControlLabel checked={(item['id'] == selectedWallet['id'] && index == selectedWallet['selectedIndex']) ? true : false } value={item['id']} control={<Radio style={{color: themeState.brandColor}} />} className={`ipayRadio m-0 ${(item['id'] == selectedWallet['id'] && index == selectedWallet['selectedIndex']) ? '' : 'hidden'}`}  />
                            </div>
                        ))
                    }
                    </RadioGroup>
                </div>
            </CardContent>

            {/** Paynow Section */}
            <Paynow actionFired={() => walletPaymentAction()} extraClassName={(firedErrorOnButton) ? 'got-error' : '' } />

        </div>
    )
}

import React, { useEffect, useState } from 'react'
import ErrorScreen from '../../components/errors/ErrorScreen';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Constant from '../../config/constant'
import { useDispatch, useSelector } from 'react-redux'
import { SettingAction } from '../../stores/actions';

function ErrorPage() {
    let { errorData } = useParams();
    const dispatch = useDispatch();
    const [errorDetails, setErrorDetails] = useState({
        errorMsg : 'Something went wrong, #VIEW-EP1'
    });

    useEffect(() => {

        if((Constant.validation.base64).test(errorData)){

            let getDecodeError = atob(errorData);

            console.log(getDecodeError);

            let getParseData = JSON.parse(getDecodeError);

            let getPaymentAction = getParseData['paymentAction'];

            dispatch(SettingAction.updatePaymentActions(getPaymentAction));

            if(getParseData['error']!=undefined){

                errorDetails['errorMsg'] = getParseData['error'];
            }

            if(getParseData['redirectTo']!=undefined && getParseData['redirectTo']!=''){

                errorDetails['redirectTo'] = getParseData['redirectTo'];
            }

            if(getParseData['redirectData']!=undefined && getParseData['redirectData']!=''){

                errorDetails['redirectData'] = getParseData['redirectData'];
            }

            setErrorDetails({...errorDetails});
        }
        else{

            
        }

    },[]);

    return (
        <div className="min-vh-100 d-flex flex-column justify-content-center">
            <div className="row">
                <div className='col-lg-4'></div>
                <div className='col-lg-4'>
                    <ErrorScreen {...errorDetails} />
                </div>
                <div className='col-lg-4'></div>
            </div>
        </div>
       
    )
}

export default ErrorPage;

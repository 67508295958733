
/**
 * 
 * @param {object} data
 * name : Name of Business
 * requestId : PG Request Id
 * logo : Logo path or url
 * payableAmount : Payable Amount
 * @returns 
 */
const updateUserDetails = (data) => {

    return {
        type: 'UPDATE_USER_DATA',
        payload: {
            displayName : data['displayName'],
            requestId : data['requestId'],
            payableAmount : data['payableAmount'],
            envMode : (data['envMode']!=undefined && data['envMode']!='') ? data['envMode'] : 'TESTING',
            logo : data['logo'],
        }
    }
}

/**
 * 
 * @param {string} mode
 * @returns 
 */
const updateEnvMode = (mode) => {

    return {
        type: 'UPDATE_ENVIRONMENT_MODE',
        payload: {
            envMode : mode
        }
    }
}

/**
 * 
 * @param {object} data
 * requestId : Request Id
 * @returns 
 */
const updateUserJourneyId = (data) => {

    return {
        type: 'UPDATE_JOURNEY_ID',
        payload: {
            journeyId : data['journeyId'],
        }
    }
}

/**
 * 
 * @param {object} data
 * payerLoginStatus : Payer Login Status
 * @returns 
 */
const updatePayerLogin = (status) => {

    return {
        type: 'UPDATE_PAYER_LOGIN',
        payload: {
            payerLogin : status,
        }
    }
}

export default {
    updateUserDetails,
    updateEnvMode,
    updateUserJourneyId,
    updatePayerLogin,
}
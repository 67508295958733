import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { indianCurrency, redirectForm, redirectRawForm, redirectSource } from '../../helpers/ipayHelper';
//import SuccessPayment from '../../assets/success.svg';
import { Box, Button } from '@mui/material';
import { PaymentConfirmationAction } from '../../stores/actions';
import _ from 'lodash';

export default function PaymentConfirmation(props) {

    const settingState = useSelector(state => state.settingReducer);
    const themeState = useSelector(state => state.themeReducer);
    const userState = useSelector(state => state.userReducer);
    const configState = useSelector(state => state.configReducer);
    const paymentConfirmationState = useSelector(state => state.paymentConfirmationReducer);
    const dispatch = useDispatch();

    const redirectTimerConfig = {
        totalTime : 10,
        running : 10
    }
    const [redirectTimer, setRedirectTimer] = useState(redirectTimerConfig);

    useEffect(() => {

       

        return () => {
            clearScreen();
        }

    },[]);

    //Start Redirect timer on success
    useEffect(() => {

        if(paymentConfirmationState.actionName == 'RedirectToSource' || paymentConfirmationState.actionName == 'RetryWithRedirect'){
            startRedirectTimer();
        }

    },[paymentConfirmationState.actionName])

    const clearScreen = () => {

        setRedirectTimer(redirectTimerConfig);

    }

    const startRedirectTimer = () => {

        const timer = setInterval(() => {

            if(redirectTimer['running'] == 1){
                clearInterval(timer);
                setRedirectTimer(redirectTimerConfig);
                redirectToSource();
                return false;
            }

            redirectTimer['running'] = redirectTimer['running'] - 1
            setRedirectTimer({...redirectTimer});

        }, 1000);
    }

    const onRetryBtn = () => {

        dispatch(PaymentConfirmationAction.paymentRetrySetup({
            componentName : paymentConfirmationState.componentName,
            actionName : paymentConfirmationState.actionName,
        }));

        dispatch(PaymentConfirmationAction.showPaymentConfirmation({
            clear : true
        }));
    }

    const redirectToSource = () => {

        let params = {
            ipayRequestId : configState.requestId,
            description : paymentConfirmationState.description,
            extraData : configState.extraData,
        }

        let callBackUrl = '';

        if(['SUCCESS','PENDING'].includes(paymentConfirmationState.status)){
            callBackUrl = configState.successUrl;
        }
        else{
            callBackUrl = configState.failedUrl;
            params['code'] = 'ERROR';
        }

        if(settingState.paymentAction == 'WINDOW'){

            if(paymentConfirmationState.redirectToSource!=''){
                callBackUrl = paymentConfirmationState.redirectToSource;
            }

            params['url'] = callBackUrl;

            if(!_.isEmpty(paymentConfirmationState.redirectToSourceData)){
                params = {...params, ...paymentConfirmationState.redirectToSourceData}
            }

            redirectForm(params);
        }
        else{

            if(!_.isEmpty(paymentConfirmationState.redirectToSourceData)){
                params = {...params, ...paymentConfirmationState.redirectToSourceData}
            }

            redirectSource(params);
        }

    }

    return (
        <div className='d-flex flex-column height-100p'>
            <Box 
                className={ (paymentConfirmationState.status == 'SUCCESS') ? 'successBackground' : 
                    (paymentConfirmationState.status == 'PENDING') ? 'pendingBackground' : 
                        (paymentConfirmationState.status == 'FAILED') ? 'failedBackground' : ''  }
            >   
                <div onClick={() => redirectToSource()} className='mark-close pointer' style={{position: 'absolute', right: '10px', top: '10px' }}>
                    <i className="material-icons text-white">cancel</i>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center height-100p'>
                    <embed 
                        src={
                            (paymentConfirmationState.status == 'SUCCESS') ? process.env.REACT_APP_STATIC_URL + 'success.svg' : 
                                (paymentConfirmationState.status == 'PENDING') ?  process.env.REACT_APP_STATIC_URL + 'pending.svg' : 
                                    (paymentConfirmationState.status == 'FAILED') ?  process.env.REACT_APP_STATIC_URL + 'failed.svg' : ''
                        } 
                        className="center width-50p"
                    />
                    <p className='text-white font-size-18 font-weight-600'>
                        {
                            (paymentConfirmationState.status == 'SUCCESS') ? 'Payment successful' 
                                : (paymentConfirmationState.status == 'FAILED') ? 'Payment failed' 
                                    : 'Under Process'
                        }
                    </p>
                    <p className='text-white font-size-18 font-weight-600'>{ indianCurrency(userState.payableAmount)} </p>
                </div>
            </Box>
            <Box style={{
                height:'30%',
                backgroundColor: '#FFFFFF',
            }}>
                <div className='d-flex flex-column justify-content-between height-100p'>
                    {
                        (paymentConfirmationState.status == 'SUCCESS') ? <>
                            <div className='p-3'>
                                <p className='mb-1 font-size-18 font-weight-bold'>{userState.displayName}</p>
                                <p className='mb-0 font-size-12 text-lightdark'> { paymentConfirmationState.paymentData.paymentCreated }</p>
                            </div>
                            <div className='p-3'>
                                <p className='mb-0 font-size-14'> <span className='text-lightdark'>{paymentConfirmationState.paymentData.paymentMode} |</span>  {paymentConfirmationState.paymentData.paymentId}</p>
                                <p className='mb-0 font-size-10 text-lightdark'>please contact helpdesk</p>
                            </div>
                        </> : (paymentConfirmationState.status == 'PENDING') ? <>
                        
                        
                        </> : (paymentConfirmationState.status == 'FAILED') ? <>
                            <div className='p-3'>
                                <p className='mb-0 font-size-14 text-break font-weight-bold text-center'>{paymentConfirmationState.description}</p>
                            </div>
                        </> : ''
                    }

                    {
                        (paymentConfirmationState.actionName == 'PaymentRetry' || paymentConfirmationState.actionName == 'RetryWithRedirect') ? 
                            <div className='d-flex flex-row justify-content-center align-items-center height-100p'>
                                <Button onClick={() => onRetryBtn()} variant="outlined" size="small" className='btn-primaryBorderWithWiteFill'>Retry</Button>
                            </div> : ''
                    }

                    {
                        (paymentConfirmationState.actionName == 'RedirectToSource' || paymentConfirmationState.actionName == 'RetryWithRedirect') ? 
                            <div className='p-3' style={{backgroundColor:'aliceblue'}}>
                                <p className='mb-0 font-size-12 text-primary'>Redirecting in {redirectTimer.running} seconds...</p>
                            </div> : ''
                    }
                    
                </div>
            </Box>
        </div>
    )
}

import React, {useState, useEffect, } from 'react'
import './PaymentMethod.scss';

import visaCard from '../../assets/visa-card.svg';
import upiLogo from '../../assets/upi.svg';
import paytm from '../../assets/paytm.svg';
import googlepay from '../../assets/googlepay.svg';
import phonepe from '../../assets/phonepe.svg';
import bhim from '../../assets/bhim.svg';
import upiIcon from '../../assets/upi-icon.svg';
import qrCode from '../../assets/qrcode.png';
import IpayLogo from '../../assets/ipay_logo.svg';


import { ReactSVG } from "react-svg";
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux'
import { SettingAction } from '../../stores/actions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Slide, Fade } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Paynow from '../pay-now/Paynow';
import CircularProgress from '@mui/material/CircularProgress';

import { indianCurrency, verifyRequest } from '../../helpers/ipayHelper';

import { apiRequest } from '../../network/ApiClient';
import Constant from '../../config/constant';

import _ from 'lodash';

function PaymentMethod(props) {
    const settingState = useSelector(state => state.settingReducer);
    const themeState = useSelector(state => state.themeReducer);
    const userState = useSelector(state => state.userReducer);
    const configState = useSelector(state => state.configReducer);
    const paymentMethodState = useSelector(state => state.paymentMethodReducer);
    const dispatch = useDispatch();

    var startCountDown;
    const [qrTimerConfig, setQrTimerConfig ] = useState({
        totalMinTimer : 1,
        totalSecTimer : 60,
        runningMin : 1,
        runningSec : 10,
        actualTime : '01:10',
    });

    const [startQrPayment, setStartQrPayment] = useState(false);
    const [isQrTimeout, setIsQrTimeout] = useState(false);
    const [isVpaValid, setIsVpaValid] = useState(false);
    const [vpaValue, setVpaValue] = useState('');
    const [searchVpaPaymentModalVisible, setSearchVpaPaymentModalVisible] = useState(false);

    //Payment Method Screens
    const [paymentMethodList, setPaymentMethodList] = useState([]);
    const [showQrPayment, setShowQrPayment] = useState(false);
    const [showSavedPayment, setShowSavedPayment] = useState(false);

    useEffect(() => {

        configPaymentMethods();

        return () => {

            _clearScreen();
        }

    },[]);

    const _clearScreen = () => {

        setPaymentMethodList([]);

        setShowQrPayment(false);

        setShowSavedPayment(false);
    }

    const configPaymentMethods = () => {

        let getQr = _.filter(paymentMethodState.paymentMethods, {id : 1});
        
        if(getQr.length > 0){
            setShowQrPayment(true);
        }

        //Remove QR Payment
        let getAllPay = paymentMethodState.paymentMethods;

        let restPayment = _.reject(getAllPay, {id : 1});

        setPaymentMethodList(restPayment);

        if(restPayment.length == 1){

            clickedPaymentMethod(restPayment[0].id);
            return false;
        }

    }

    const clickedPaymentMethod = (paymentId) => {

        if(paymentId=='1'){ //UPI

            return false;
        }

        if(paymentId=='2'){ //Card Payment
            showCardPaymentScreen();
            return false;
        }

        if(paymentId=='3'){ //Net Banking Payment
            showNetbankingPaymentScreen();
            return false;
        }

        if(paymentId=='4'){ //wallet Payment
            showWalletPaymentScreen();
            return false;
        }

        if(paymentId=='5'){ //EMI Payment
            showEmiPaymentScreen();
            return false;
        }

        if(paymentId=='6'){ //Pay later Payment
            showPayLaterPaymentScreen();
            return false;
        }

        if(paymentId=='7'){
            showIpayWalletPaymentScreen();
            return false;
        }

    }   

    useEffect(() => {

        if(startQrPayment){
            startQrTimer();
        }

    },[qrTimerConfig]);

    const onStartQrPayment = () => {

        setStartQrPayment(true);

        startQrTimer();
    }
    
    const startQrTimer = () => {

        startCountDown = setTimeout(() => {

            if(qrTimerConfig['runningMin']==0 && qrTimerConfig['runningSec']==0){
                clearInterval(startCountDown);
                setIsQrTimeout(true);
                return false;
            }

            let newMin = parseInt(qrTimerConfig['runningMin']);

            let newSec;

            if(qrTimerConfig['runningSec']==0){

                newMin = parseInt(qrTimerConfig['runningMin']) - 1;

                newSec = qrTimerConfig['totalSecTimer'];
            }
            else{

                newSec = parseInt(qrTimerConfig['runningSec']) - 1;
            }

            setQrTimerConfig((prevState) => {

                let newTimer = {
                    runningSec : newSec,
                    runningMin : newMin,
                    actualTime : String(newMin).padStart(2, '0') +":"+ String(newSec).padStart(2, '0'),
                }

                return {...prevState, ...newTimer}
            });

        },1000)

    }

    const generateNewQr = async () => {

        setIsQrTimeout(false);

        setQrTimerConfig((prevState) => {
            let newTimer = {
                runningSec : prevState['totalSecTimer'],
                runningMin : prevState['totalMinTimer'],
                actualTime : String(prevState['totalMinTimer']).padStart(2, '0') +":"+ String(prevState['totalSecTimer']).padStart(2, '0'),
            }

            return {...prevState, ...newTimer}
        });

        closeVpaScreen();

        let verifiedData = await apiRequest({
            method : 'POST',
            path : "auth/appPreferences",
            credentials : {
                clientId : configState['keyId'],
                clientSecret : userState['journeyId'],
            }
        },{
            name : "dsf"
        }).catch(err => {

            throw err;
        });

        console.log(verifiedData);
    }

    const openVpaScreen = () => {

        setVpaValue("");
        setIsVpaValid(false);

        let item = document.querySelector('.vpaScreen');

        if(item.classList.contains('show')){
            closeVpaScreen();
        }
        else{
            item.classList.add('show');
        }
    }

    const closeVpaScreen = () => {
        document.querySelector('.vpaScreen').classList.remove('show');
    }

    const onChangeVpaValue = (event) => {

        let text = event.target.value;

        setVpaValue(event.target.value);

        let isValid = verifyRequest({
            'vpa' : 'VAP'
        },{
            'vpa' : Constant.validation.vpa
        },{
            'vpa' : event.target.value
        });

        if(!isValid.resp){
            setIsVpaValid(false);
        }
        else{
            setIsVpaValid(true);
        }
    }

    const vpaPaymentAction = () => {

        //alert('vpa payment');

        setSearchVpaPaymentModalVisible(true);
    }

    const closeSearchVpaPaymentModal = (closeReason='') => {
        //console.log('ddddd',closeReason);

        if(closeReason=='backdropClick'){
            return false;
        }

        setSearchVpaPaymentModalVisible(false);
    }

    const showCardPaymentScreen = () => {
        dispatch(SettingAction.updateCardPaymentScreen(true));
    }

    const showWalletPaymentScreen = () => {
        dispatch(SettingAction.updateWalletPaymentScreen(true));
    }

    const showNetbankingPaymentScreen = () => {
        dispatch(SettingAction.updateNetbankingPaymentScreen(true));
    }

    const showIpayWalletPaymentScreen = () => {
        dispatch(SettingAction.updateIpayWalletPaymentScreen(true));
    }

    const showEmiPaymentScreen = () => {

    }

    const showPayLaterPaymentScreen = () => {

    }

    const loadSvgIconBrandColor = (svg,paymentId) => {
        
        if(paymentId == '2'){
            //console.log(svg.childrenList);
            //svg.classList.add('paymentMode'+item.id)
            //svg.setAttribute('style', 'width: 200px')
            svg.firstElementChild.children[0].setAttribute("fill",themeState.brandColor);
            svg.firstElementChild.children[2].setAttribute("fill",themeState.brandColor);
        }

        if(paymentId == '3'){
            svg.firstElementChild.children[1].setAttribute("fill",themeState.brandColor);
        }

        if(paymentId == '4'){
            svg.firstElementChild.children[0].setAttribute("fill",themeState.brandColor);
        }

        if(paymentId == '5'){
            svg.firstElementChild.setAttribute("fill",themeState.brandColor);
        }

        if(paymentId == '6'){
            svg.firstElementChild.setAttribute("fill",themeState.brandColor);
        }

        if(paymentId == '7'){
            svg.firstElementChild.children[0].setAttribute("fill",themeState.brandColor);
            svg.firstElementChild.children[1].setAttribute("fill",themeState.brandColor);
        }
    }
      

    return (
        <div className='payment-main-section'>
            <CardContent 
                className={`body-container`}
                style={{
                    height: (settingState.visiblePaymentSection)? settingState.visiblePaymentSectionHeight+'px' : settingState.mainSectionHeight+'px',
                }}
            >
                {
                    showQrPayment ? <>
                        <p className='font-size-14 font-weight-600 text-dark mb-2'>UPI/ Scan and Pay</p>
                        <div className='qr-section p-2 mb-4'>
                            <div className='d-flex flex-row align-items-center mb-2'>
                                <div className='qr-img'>
                                    <img className={`${(isQrTimeout || !startQrPayment)  ? 'blur' : ''}`} src={qrCode} />
                                    
                                    {
                                        isQrTimeout ?
                                            <div className='new-qr-btn'>
                                                <Button
                                                    onClick={() => generateNewQr()}
                                                    variant="contained" 
                                                    size="small" 
                                                    style={{
                                                        backgroundColor: themeState.bgColor,
                                                        color: themeState.textColor, 
                                                    }}
                                                >Refersh QR</Button>
                                            </div> : ''
                                    }

                                    {
                                        !startQrPayment ? 
                                            <div className='new-qr-btn'>
                                                <Button
                                                    onClick={() => onStartQrPayment()}
                                                    variant="contained" 
                                                    size="small" 
                                                    style={{
                                                        backgroundColor: themeState.bgColor,
                                                        color: themeState.textColor, 
                                                    }}
                                                >Show QR</Button>
                                            </div> : ''
                                    }

                                    
                                </div>
                                <div className='qr-desc'>
                                    <div className='d-flex flex-column'>
                                        <span className='font-size-12 font-weight-500 text-lightdark mb-2'>Scan the QR from your mobile using any UPI app.</span>
                                        <div className='ven-list mb-1'>
                                            <img src={googlepay} className='ven-logo' />
                                            <img src={phonepe} className='ven-logo' />
                                            <img src={paytm} className='ven-logo' />
                                            <img src={bhim} className='ven-logo' />
                                        </div>

                                        {
                                            startQrPayment ? <>
                                                {
                                                    !isQrTimeout ? 
                                                        <p className='font-size-10 font-weight-500 text-lightdark mb-2'>QR Code is valid for <span className='text-danger'> { qrTimerConfig['actualTime']} </span> minutes </p> 
                                                            :
                                                        <p className='font-size-10 font-weight-500 text-lightdark mb-2'>The previous QR got expired</p>
                                                }
                                            </> : ''
                                        }
                                        <span className='text-primary font-size-10 font-weight-400 pointer' onClick={() => openVpaScreen()}> <img src={upiIcon} /> <u>Enter VPA</u></span>
                                    </div>
                                </div>
                            </div>
                            <div className='vpaScreen'>
                                <div className='width-100p mt-4 mb-2'>
                                    <FormControl fullWidth>
                                        <TextField 
                                            label="VPA / UPI Id" 
                                            variant="outlined" 
                                            name='vpaUpiId' 
                                            className='ipayInput'
                                            value={vpaValue}
                                            onChange={(event) => onChangeVpaValue(event)}
                                            error={(!isVpaValid && vpaValue!="") ? true : false }
                                            helperText={ (!isVpaValid && vpaValue!="") ? "Please enter a valid VPA of the form uname@bank" : ""}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </> : <></>
                }
                
                {
                    showSavedPayment ? <>
                        <p className='font-size-14 font-weight-600 text-dark mb-2'>Saved Payment Modes</p>
                        <div className='save-payment-section mb-4'>
                            <div className='d-flex flex-column'>
                                <RadioGroup
                                    defaultValue=""
                                    name="radio-buttons-group"
                                >
                                    <div className='save-payment-card mb-2'>
                                        <img src={visaCard} style={{width: '46px'}} />
                                        <div className='d-flex flex-column justify-content-center flex-grow-1 ms-3'>
                                            <span className='font-size-12 font-weight-600 text-dark'>Raghav Dua</span>
                                            <span className='font-size-10 font-weight-400 text-lightdark'>&#x2022;&#x2022;&#x2022;&#x2022;0055</span>
                                        </div>
                                        <FormControlLabel value="card" control={<Radio style={{color : themeState.brandColor}} />} className="ipayRadio m-0" />
                                    </div>
                                    <div className='save-payment-upi'>
                                        <img src={upiLogo} style={{width: '46px'}} />
                                        <div className='d-flex flex-row align-items-center flex-grow-1 ms-3'>
                                            <span className='font-size-12 font-weight-700 text-dark'>8902211226@paytm</span>
                                        </div>
                                        <FormControlLabel value="upi" control={<Radio style={{color : themeState.brandColor}} />} className="ipayRadio m-0" />
                                    </div>
                                </RadioGroup>
                            </div>
                        </div>
                    </> : <></>
                }
                
                {
                    paymentMethodList.length > 0 ? <>
                        <p className='font-size-14 font-weight-600 text-dark mb-2'>Payment Modes</p>
                        <div className='payment-options mb-4'>
                            {
                                paymentMethodList.map((item, index) => {
                                    
                                    return (
                                        <div key={index} className='payment-mode  mb-2 pointer' onClick={() => clickedPaymentMethod(item.id)}>
                                            <ReactSVG 
                                                src={require(`../../assets/payment-method/${item.id}.svg`)} 
                                                style={{width: '20px'}}
                                                beforeInjection={(svg) => loadSvgIconBrandColor(svg,item.id ) }
                                            />
                                            <div className='d-flex flex-column justify-content-center flex-grow-1 ms-3'>
                                                <span className='font-size-14 font-weight-600 text-dark'>{item.name}</span>
                                                <span className='font-size-10 font-weight-400 text-lightdark'>{item.helpText}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </> : <></>
                }

                
                <div className='payment-options mb-4'>
                    {/* <div className='payment-mode  mb-2 pointer' onClick={() => showCardPaymentScreen()}>
                        <img src={cardIcon} style={{width: '20px'}} />
                        <div className='d-flex flex-column justify-content-center flex-grow-1 ms-3'>
                            <span className='font-size-14 font-weight-600 text-dark'>Cards</span>
                            <span className='font-size-10 font-weight-400 text-lightdark'>RuPay, VISA, Master Card, Diners, Amex & more</span>
                        </div>
                    </div>
                    <div className='payment-mode mb-2 pointer' onClick={() => showWalletPaymentScreen()}>
                        <img src={walletIcon} style={{width: '20px'}} />
                        <div className='d-flex flex-column justify-content-center flex-grow-1 ms-3'>
                            <span className='font-size-14 font-weight-600 text-dark'>Prepaid Wallets</span>
                            <span className='font-size-10 font-weight-400 text-lightdark'>Instantpay, PayTm, PhonePe & more</span>
                        </div>
                    </div>
                    <div className='payment-mode mb-2 pointer' onClick={() => showNetbankingPaymentScreen()}>
                        <img src={cardIcon} style={{width: '20px'}} />
                        <div className='d-flex flex-column justify-content-center flex-grow-1 ms-3'>
                            <span className='font-size-14 font-weight-600 text-dark'>Net Banking</span>
                            <span className='font-size-10 font-weight-400 text-lightdark'>SBI, HDFC, ICICI & many more</span>
                        </div>
                    </div>
                    <div className='payment-mode mb-2 pointer' onClick={() => showIpayWalletPaymentScreen()}>
                        <img src={cardIcon} style={{width: '20px'}} />
                        <div className='d-flex flex-column justify-content-center flex-grow-1 ms-3'>
                            <span className='font-size-14 font-weight-600 text-dark'>Instantpay Wallet</span>
                            <span className='font-size-10 font-weight-400 text-lightdark'>pay from your instantpay account</span>
                        </div>
                    </div> */}
                </div>
                
                <Dialog
                    className='ipayModalBottom'
                    open={searchVpaPaymentModalVisible}
                    keepMounted
                    onClose={(event,reason) => closeSearchVpaPaymentModal(reason) }
                    container={() => document.getElementById('mainScreenContent')}
                    TransitionComponent={Fade}
                    disableEscapeKeyDown={true}
                >
                    <DialogContent>
                        <div className='vpaPaymentModalBody'>
                            <p className='font-size-12 font-weight-500 text-center text-dark mb-4'>Please open your UPI app and approve payment Request of <b>{ indianCurrency(userState.payableAmount) }</b></p>
                            <div className='d-flex flex-row justify-content-center align-items-center mb-3'>
                                <CircularProgress className='ipayLoaderPrimary' />
                            </div>
                            <p className='text-center font-size-10 font-weight-500 text-primary mb-4 pointer' onClick={() => closeSearchVpaPaymentModal()}> <u>Cancel Payment</u></p>
                            <div className='d-flex flex-row justify-content-center align-items-center'>
                                <span className='text-lightdark font-size-10 font-weight-400'>Secured By&nbsp;&nbsp;</span>
                                <img src={IpayLogo} /> 
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>

            </CardContent>

            {/** Paynow Section */}
            <Paynow actionFired={() => vpaPaymentAction()} />
        </div>
    )
}

export default PaymentMethod;

const initialState = {
    paymentMethods : {},
    selectedPaymentMethod : {},
};

export default (state = initialState, action) => {
    switch (action.type) {

        case 'UPDATE_PAYMENT_METHODS':

            const methodData = action.payload;
            return { ...state, ...methodData };

        case 'UPDATE_SELECTED_PAYMENT_METHOD':

            const selectedMethodData = action.payload;
            return { ...state, ...selectedMethodData };

        default:
            return state;
    }
};
import React, {useEffect, useRef, useState} from 'react'
import './Login.scss';
import { useSelector, useDispatch } from 'react-redux'
import {
    FormControl,
    TextField,
    MenuItem,
    InputLabel,
    Select,
    Checkbox,
    CardContent,
    Button,
    FormControlLabel
} from '@mui/material'

import IndiaFlag from '../../assets/ind_flag.svg';
import { SettingAction, UserAction, CustomerAction } from '../../stores/actions'
import Constant from '../../config/constant'
import { addToLocalStorage, getFromLocalStorage } from '../../helpers/ipayHelper';

function Login(props) {

    const settingState = useSelector(state => state.settingReducer);
    const themeState = useSelector(state => state.themeReducer);
    const userState = useSelector(state => state.userReducer);
    const dispatch = useDispatch();

    const [selectCountryCode, setSelectCountryCode] = useState('');
    const [mobile, setMobile] = useState('');
    const [isMobileValid, setIsMobileValid] = useState(false);
    const [emailId, setEmailId] = useState('');
    const [isEmailIdValid, setIsEmailIdValid] = useState(false);
    const [isSaveContactDetailChecked, setIsSaveContactDetailChecked] = useState(true);
    const [submitBtnErrorAnimation, setSubmitBtnErrorAnimation] = useState(false);
    const loginBtnSubmitRef = useRef(null);
    const loginSkipBtnSubmitRef =  useRef(null);
    const mobileNumberInputRef = useRef(null);

    //set false on animation error
    useEffect(() => {

        if(submitBtnErrorAnimation){

            loginBtnSubmitRef.current.classList.add('horizontal-shake');

            setTimeout(() => {
                setSubmitBtnErrorAnimation(false);
                loginBtnSubmitRef.current.classList.remove('horizontal-shake');
            }, 500);
        }

    },[submitBtnErrorAnimation]);


    const onChangeMobileNumber = (event) => {

        let text = (event.target.value).trim();
        text = text.replace(/[^0-9]/g, '')

        if (text.length > 0) {

            let regnum = new RegExp(Constant.validation.mobile);
            setIsMobileValid(false);
            if (regnum.test(text)) {

                if(text.length!=10){
                    setIsMobileValid(true);
                    return false;
                }

                setIsMobileValid(true);
            }
            else {
                
                if (['6', '7', '8', '9'].includes(text[0])) {
                    text = text.replace(/[^0-9]/g, '')
                }
                else {
                    setIsMobileValid(false);
                }

            }

            setMobile(text);
        }
        else {
            setMobile('');
            setIsMobileValid(false);
        }
    }

    const onChangeEmailId = (event) => {

        let text = (event.target.value).trim();

        if (text.length > 0) {

            setIsEmailIdValid(false);
            let regnum = Constant.validation.emailId
            if (regnum.test(text)) {
                setIsEmailIdValid(true);
            }

            setEmailId(text);
        }
        else{
            setEmailId('');
            setIsEmailIdValid(false);
        }
    }

    const onClickedContactSaveCheckbox = (event) => {

        setIsSaveContactDetailChecked(event.target.checked);
    }

    const onSubmitLogin = () => {

        //check mobile
        if(!isMobileValid){
            setMobile(" ");
            setSubmitBtnErrorAnimation(true);
            return false;
        }

        if(!isEmailIdValid){
            setEmailId(" ");
            setSubmitBtnErrorAnimation(true);
            return false;
        }

        if(mobile!='' && emailId!=''){

            dispatch(CustomerAction.updateCustomer({
                mobile : mobile,
                emailId : emailId
            }));

            if(isSaveContactDetailChecked){
                let getExistingProfile = getFromLocalStorage('ipayPg.userProfile');

                if(getExistingProfile){

                    let newProfile = {
                        email : emailId,
                        mobile : mobile,
                    };

                    //let newItems = getExistingProfile.concat(newProfile);

                    addToLocalStorage('ipayPg.userProfile', newProfile)

                }
                else{
                    addToLocalStorage('ipayPg.userProfile', {
                        email : emailId,
                        mobile : mobile,
                    })
                }
            }

            hideLoginScreen();
        }

    }

    const onSkipLogin = () => {
        hideLoginScreen();
    }

    const hideLoginScreen = () => {
        dispatch(SettingAction.updateLoginScreen(false));
    }

    return (
        <div className='loginScreen'>
            <CardContent>
                <p className='font-size-14 font-weight-600 mb-4'>Contact Details</p>
                <div className='d-flex flex-row mb-4'>
                    <div className='width-25p'>
                        <FormControl fullWidth disabled>
                            <Select
                                value={selectCountryCode}
                                displayEmpty
                            >
                                <MenuItem value=""><img src={IndiaFlag}/></MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className='width-80p ms-2'>
                        <FormControl fullWidth>
                            <TextField 
                                inputRef={mobileNumberInputRef}
                                autoFocus={true}
                                label="Mobile Number" 
                                variant="outlined" 
                                name='mobile' 
                                className='ipayInput'
                                value={mobile}
                                error={ (mobile!='' && !isMobileValid) ? true : false}
                                helperText={(mobile!='' && !isMobileValid) ? 'Invalid Mobile' : ''}
                                onChange={(event) => onChangeMobileNumber(event)}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='width-100p'>
                    <FormControl fullWidth>
                        <TextField 
                            label="Email ID" 
                            variant="outlined" 
                            name='emailId' 
                            className='ipayInput'
                            value={emailId}
                            error={ (emailId!='' && !isEmailIdValid) ? true : false}
                            helperText={(emailId!='' && !isEmailIdValid) ? 'Invalid Email Id' : ''}
                            onChange={(event) => onChangeEmailId(event)}
                        />
                    </FormControl>
                </div>
                <div className='width-100p'>
                    <FormControlLabel control={<Checkbox checked={isSaveContactDetailChecked} size='small' style={{color : themeState.brandColor }} onChange={($event) => onClickedContactSaveCheckbox($event) } />} label="save for the future" className='text-lightdark' />
                </div>
                <div className='bottom-section'>
                    <div className='d-flex flex-column justify-content-center align-items-center mt-4'>
                        <Button
                            ref={loginBtnSubmitRef}
                            onClick={() => onSubmitLogin()}
                            variant="contained" 
                            size="large" 
                            className={"width-90p"}
                            style={{
                                backgroundColor: themeState.bgColor,
                                color: themeState.textColor, 
                            }}
                        >Proceed</Button>

                        {
                            userState.payerLogin == 'OPTIONAL' ? 
                                <>
                                    <hr style={{margin : '5px'}}/>
                                    <Button
                                        ref={loginSkipBtnSubmitRef}
                                        onClick={() => onSkipLogin()}
                                        size="small" 
                                        className={"width-90p text-danger"}
                                    >Skip for now</Button>
                                </> : <></>
                        }
                    </div>
                </div>
            </CardContent>
        </div>
        
    )
}

export default Login;
import { legacy_createStore as createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { 
    UserReducer, 
    SettingReducer, 
    ThemeReducer, 
    ConfigReducer, 
    CustomerReducer, 
    PaymentMethodReducer,
    ProductReducer,
    PaymentConfirmationReducer
} from './reducers/index';


const rootReducer = combineReducers({
    userReducer: UserReducer,
    settingReducer: SettingReducer,
    themeReducer: ThemeReducer,
    configReducer: ConfigReducer,
    customerReducer: CustomerReducer,
    paymentMethodReducer: PaymentMethodReducer,
    productReducer: ProductReducer,
    paymentConfirmationReducer: PaymentConfirmationReducer,
});

let store;

if(process.env.REACT_APP_ENV=='development'){
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
}
else{
    store = createStore(rootReducer, applyMiddleware(thunk));
}

export default store;
import _ from 'lodash';
import { 
    Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import { SettingAction } from '../stores/actions';
import React, {useState, useEffect} from 'react'
import { ToastContainer, toast } from 'react-toastify';

/**
 * 
 * @param {*} value //Value
 * @param {*} isCurrencySymbol : To Show indian curreny symbol or not , true,false
 * @param {*} afterDecimal : After decimal show zeros
 * @returns 
 */
export const indianCurrency = (value, isCurrencySymbol = true, afterDecimal = 2) => {

    if (!isNaN(value) && value != null) {
        let currencySymbol = '₹';
        //var output = Number(input).toLocaleString('en-IN');   <-- This method is not working fine in all browsers!           
        let result = value.toString().split('.');

        let lastThree = result[0].substring(result[0].length - 3);
        let otherNumbers = result[0].substring(0, result[0].length - 3);
        if (otherNumbers != '')
            lastThree = ',' + lastThree;
        let output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

        // If Decimal Value have
        if (result.length > 1) {

            let decimal = result[1].substring(0, 2);

            if (afterDecimal != '' && afterDecimal != null) {

                decimal = result[1].substring(0, afterDecimal);

                decimal = decimal.padEnd(afterDecimal, '0');
            }

            output += "." + decimal;
        }
        else {
            let zeros = '0';
            for (let index = 1; index < Number(afterDecimal); index++) {
                zeros = zeros + '0';
            }

            if (afterDecimal == 0) {
                output = output;
            }
            else {
                output = output + '.' + zeros;
            }
        }

        if (!isCurrencySymbol) {

            return output;
        }
        else {
            return currencySymbol + output;
        }
    }
}

/**
 * 
 * @param {*} request //Request Key
 * @param {*} regex : //Regex of Request
 * @param {*} p : //Object for Values to check
 * @returns 
 */
export const verifyRequest = (request,regex,p) => {

    let output = {
        resp: 0,
        msg : ''
    };

    if(p==undefined){
       output.msg = 'Missing key request';
       return output;
    }
    
    for(let req in request)
    { 
        if(!(req in p)){
            output.msg = 'Missing key '+req;
            return output;
        }

        if(request[req]===''){
        
            if((req in regex) && regex[req]!=="" && eval('p.'+req)!=''){

                let oregex = regex[req];
                let omsg = 'value of '+ req;

                if(_.isArray(regex[req])){

                    oregex = regex[req][0];
                    omsg = regex[req][1];
                }

                var re = new RegExp(oregex);
                if(!re.test(eval('p.'+req))){
                    output.msg = 'Invalid '+omsg;
                    return output;
                }
            }
        }
        else if(_.isArray(request[req])){
            
            if(!(request[req]).includes(eval('p.'+req)))
            {
                output.msg = 'Invalid key '+req;
                return output;
            }
        }
        else if(typeof request[req]==='object'){
            
            for(let obj in request[req]){
               
                if(typeof eval('p.'+req)!='object'){
                    output.msg = 'Invalid key '+req+' type';
                    return output;
                }

                if(!(obj in eval('p.'+req))){
                    output.msg = 'Missing key '+req+' : '+obj;
                    return output;
                }
                
                if(request[req][obj]===''){

                    if((obj in eval('regex'.req)) && regex[req][obj]!=="" && eval('p.'+req+'.'+obj)!=''){

                        let oregex = regex[req][obj];
                        let omsg = 'value of ' + req + " : "+ obj;
                        
                        if(_.isArray(regex[req][obj])){

                            oregex = regex[req][obj][0];
                            omsg = regex[req][obj][1];
                        }

                        var re = new RegExp(oregex);
                        if(!re.test(eval('p.'+req+'.'+obj))){
                            output.msg = 'Invalid '+omsg;
                            return output;
                        }   
                    }
                }
                else if(_.isArray(request[req][obj])){
                    
                    if(!(request[req][obj]).includes(eval('p.'+req+'.'+obj)))
                    {
                        output.msg = 'Invalid key '+req+' : '+obj;
                        return output;
                    }
                }
                else{

                    if(eval('p.'+req+'.'+obj)==''){
                        output.msg = 'Invalid or empty '+obj;
                        return output;
                    }
                    else{
                        if(!_.isEmpty(regex) && regex[req][obj]!=undefined && regex[req][obj]!==''){

                            let oregex = regex[req][obj];
                            let omsg = request[req][obj];

                            if(_.isArray(regex[req][obj])){

                                oregex = regex[req][obj][0];
                                omsg = regex[req][obj][1];
                            }
    
                            var re = new RegExp(oregex);
                           
                            if(!re.test(eval('p.'+req+'.'+obj))){
                                output.msg = 'Invalid '+omsg;
                                return output;
                            } 
                        }
                    }
                }
            }
        }
        else{

            if(regex[req]!=undefined && regex[req]!==''){

                let oregex = regex[req];
                let omsg = request[req];

                if(_.isArray(regex[req])){
                    oregex = regex[req][0];
                    omsg = regex[req][1];
                }

                var re = new RegExp(oregex);
                if(!re.test(eval('p.'+req))){
                    output.msg = 'Invalid '+omsg;
                    return output;
                } 
            }
            else{

                if(request[req]!=''){

                    if(p[req]==''){
                        output.msg = 'Invalid '+request[req];
                        return output;
                    }
                }
            }
        }
    }

    output.resp = 1;
    return output;
}


export const notify = (p) => {

    let defaultParam = {
        msg : 'something went wrong',
        autoClose : 6000,
        pauseOnHover: true
    }

    let options = {
        type : 'error'
    }

    if(_.has(p,'msg')){
        defaultParam['msg'] = p['msg'];
    }

    if(_.has(p,'type')){
        options['type'] = p['type'];
    }

    if(_.has(p,'autoClose')){
        options['autoClose'] = p['autoClose'];
    }

    
    toast(defaultParam['msg'], options);
}

/**
 * to get current lat long
 */
export const getLocation = async (allowAccurate = false) => {

    return new Promise((resolve, reject) => {

        navigator.geolocation.getCurrentPosition((position) => {

            let latGeo = (position.coords['latitude']).toFixed(5);

            let lonGeo = (position.coords['longitude']).toFixed(5);

            let cords = {
                latitude : (latGeo).toString(),
                longitude : (lonGeo).toString()
            };
            
            resolve(cords);

        }, (err) => {
            if(err.code!=1){

                let cords = {
                    latitude: 0.0000,
                    longitude: 0.0000
                };

                resolve(cords);
            }
            else{
                reject(err.message);
            }
        },{
            maximumAge : 600000, //10 Min converted to mili sec
        });
    }); 
}

/**
 * 
 * @param {*} type //IFRAME,WINDOW
 */
export const requestWindowType = (type) => {

    let getType = '';

    if(window.self !== window.top) {//On iframe

        getType = 'IFRAME';
    }
    else { //Not in iframe

        getType = 'WINDOW';
    }

    if(type!=''){

        if(type==getType){
            return true;
        }
        else{
            return false;
        }
    }
}

/**
 * Set Cookies
 * @param {*} name 
 * @param {*} value 
 * @param {*} days 
 */
export const setCookie = (name,value,days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

/**
 * Get Cookie
 * @param {*} name 
 * @returns 
 */
export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

/**
 * Remove Cookie
 * @param {*} name 
 */
export const eraseCookie = (name) => { 
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

/**
 * @param {*} options
 * options.url 
 * options.data must be object
 */
export const redirectRawForm = (options) => {

    let form = document.createElement("form");
    form.setAttribute('method',"POST");
    form.setAttribute('action', options.url);
    form.setAttribute('style',"visibility: hidden;");

    for(let item in options.data){

        let input = document.createElement("input");
        input.setAttribute('type',"text");
        input.setAttribute('name',item);
        input.setAttribute('value', options.data[item]);
        form.appendChild(input);
    }

    document.getElementsByTagName('body')[0].appendChild(form);

    form.submit();

}

/**
 * @param {*} options
 * options.url 
 * options.data must be object
 * code //ERROR, SUCCESS,
 */
export const redirectForm = (options) => {

    let redirectUrl = options.url;

    delete options.url;

    let form = document.createElement("form");
    form.setAttribute('method',"POST");
    form.setAttribute('action', redirectUrl);
    form.setAttribute('style',"visibility: hidden;");

    let defautData = {
        code : "",
        description : '',
        ipayRequestId : '',
    }

    if(options['code']!=undefined && options['code']!=''){
        defautData['code'] = options['code'];
    }

    if(options['description']!=undefined && options['description']!=''){
        defautData['description'] = options['description'];
    } 

    defautData = {...defautData, ...options};

    for(let item in defautData){

        if(item == 'extraData'){

            for(let optional in  defautData['extraData']){

                let input = document.createElement("input");
                input.setAttribute('type',"text");
                input.setAttribute('name','extraData['+optional+']');
                input.setAttribute('value', defautData['extraData'][optional]);
                form.appendChild(input);
            }
        }
        else{

            let input = document.createElement("input");
            input.setAttribute('type',"text");
            input.setAttribute('name',item);
            input.setAttribute('value', defautData[item]);
            form.appendChild(input);
        }
    }

    document.getElementsByTagName('body')[0].appendChild(form);

    form.submit();

}

/**
 * @param {*} options 
 * options must be object
 * code //ERROR, SUCCESS,
 * description : About code message
 * data : //for send to end source
 */
export const redirectSource = (options) => {

    let defautData = {
        code : "",
        description : '',
        ipayRequestId : '',
    };

    if(options['code']!=undefined && options['code']!=''){
        defautData['code'] = options['code'];
    }

    if(options['description']!=undefined && options['description']!=''){
        defautData['description'] = options['description'];
    }

    defautData = {...defautData, ...options};

    window.parent.postMessage({
        actionName : 'closed',
        data : defautData,
    }, "*");
}

/**
 * Get text color based on brand theme
 * @param {*} bgColor 
 * @param {*} lightColor = '#FFFFFF'
 * @param {*} darkColor = '#000000'
 * @returns 
 */
export const pickTextColorBasedOnBgColorAdvanced = (bgColor, lightColor = '#FFFFFF', darkColor = '#000000') => {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return (L > 0.179) ? darkColor : lightColor;
}

/**
 * To Store
 * @param {*} key : String
 * @param {*} value : Object
 */
export const addToLocalStorage = (key,value) => {

    localStorage.setItem(key, JSON.stringify(value));
    return true;
}

/**
 * Get  Store Data
 * @param {*} key : String
 */
export const getFromLocalStorage = (key,value) => {

    let valueExist = localStorage.getItem(key);

    if(valueExist){

        return JSON.parse(valueExist);
    }
    else{
        return false;
    }
}

/**
 * Remove from  Store Data
 * @param {*} key : String
 */
export const removeFromLocalStorage = (key) => {

    let valueExist = localStorage.removeItem(key);

    return true;
}
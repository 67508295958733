import React from 'react'
import ErrorScreen from '../components/errors/ErrorScreen';

function NotFound() {
    return (
        <div>
            <ErrorScreen errorMsg={'Something went Wrong #VIEW1'}/>
        </div>
    )
}

export default NotFound;

const fullMonthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const shortMonthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const dbDateFormate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if(month.length < 2) 
        month = '0' + month;
    if(day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export const humanReadableDateFormate = (date) => {

    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if(month.length < 2) 
        month = '0' + month;
    if(day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

/**
 * 
 * @param dob Date of Birth : Eg 2005-04-17
 * @param age Age : Eg 13 or 18
 * @returns 
 */
export const isOfAge = (dob, age) => {
    // dates are all converted to date objects
    var my_dob = new Date(dob);
    var today = new Date();
    var max_dob = new Date(today.getFullYear() - age, today.getMonth(), today.getDate());
    return max_dob.getTime() > my_dob.getTime();
}

/**
 * 
 * @param dateValue Eg 02-03-1998
 * @param wantFormate Age : Y-m-d
 * @param originalFormate Age : d-m-Y
 * @returns 
 */
{/* Dec 5, 2023 | 09:37 PM */}
export const convertAsNeed = (dateValue,wantFormate,originalFormate) => {
    
    if(originalFormate=='d-m-Y'){

        if(wantFormate=='Y-m-d'){

            let splitDate = dateValue.split('-');

            return splitDate[2]+'-'+splitDate[1]+'-'+splitDate[0];
        }
    }
    else if(originalFormate=='Y-m-d'){

        if(wantFormate=='d/m/Y'){

            let splitDate = dateValue.split('-');

            return splitDate[2]+'/'+splitDate[1]+'/'+splitDate[0];
        }
        else if(wantFormate=='d M Y'){

            let splitDate = dateValue.split('-');

            return splitDate[2]+' '+shortMonthName[Number(splitDate[1]) - 1]+' '+splitDate[0];
        }
    }
    else if(originalFormate=='IST'){

        if(wantFormate=='Y-m-d H:i:s'){

            let d = new Date(dateValue),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear(),
                hours = '' +d.getHours(),
                min = '' +d.getMinutes(),
                sec = '' +d.getSeconds();

            if(month.length < 2) 
                month = '0' + month;
    
            if(day.length < 2) 
                day = '0' + day;

            if(hours.length < 2) 
                hours = '0' + hours;
    
            if(min.length < 2) 
                min = '0' + min;

            if(sec.length < 2) 
                sec = '0' + sec;

            let combineDate = [year, month, day].join('-');

            let combineTime = [hours, min, sec].join(':');
            
            //console.log([combineDate, combineTime].join(' '));
            return [combineDate, combineTime].join(' ');
        }
        
    }
    else if(originalFormate=='Y-m-d H:i:s'){

        if(wantFormate=='M d, Y | h:i A'){

            let d = new Date(dateValue),
                month =  d.getMonth(),
                day =  d.getDate(),
                year = d.getFullYear(),
                hours = d.getHours(),
                minutes = d.getMinutes(),
                sec = d.getSeconds();

            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = parseInt(minutes) < 10 ? '0'+minutes : minutes;
            hours = parseInt(hours) < 10 ? '0'+hours : hours;
            var strTime = hours + ':' + minutes + ' ' + ampm;

            return shortMonthName[month] +' '+day+', '+year+' | '+strTime;
        }
    }
}

export const currentDateTime = () => {
    
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        min = d.getMinutes(),
        sec = d.getSeconds();

    if(month.length < 2) 
        month = '0' + month;
    if(day.length < 2) 
        day = '0' + day;

    let combineDate = [year, month, day].join('-');

    let combineTime = [hours, min, sec].join(':');

    return [combineDate, combineTime].join(' ');
}

export const formatAmPm = (dateTime) => { // This is to display 12 hour format like you asked

    let makeDateTime = (dateTime).replace(" ", "T");
    let date = new Date(makeDateTime.toString());
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}


export const numberOfDays = (from='', to = '') => {

    let date1 = '';

    if(from== ''){
        date1 = new Date();
    }
    else{
        date1 = from;
    }

    let date2 = new Date();

    if(to!= ''){
        date2 = new Date(to);
    }
      
    // To calculate the time difference of two dates
    let differenceInTime = date2.getTime() - date1.getTime();
      
    // To calculate the no. of days between two dates
    let differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return parseInt(differenceInDays) + 1;
}
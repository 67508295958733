import React,{useState , useEffect} from 'react';
import './home.scss';
import MainScreen from '../../components/MainScreen';
import { useDispatch, useSelector } from 'react-redux'
import { ConfigAction, SettingAction, UserAction, CustomerAction, PaymentMethodAction, ProductAction ,ThemeAction } from '../../stores/actions';
import {
    Box,
    LinearProgress,
    Backdrop,
    CircularProgress,
    Snackbar
} from '@mui/material';
import { notify, pickTextColorBasedOnBgColorAdvanced, getFromLocalStorage } from '../../helpers/ipayHelper';
import { apiRequest } from '../../network/ApiClient';
import ErrorScreen from '../../components/errors/ErrorScreen';
import { requestWindowType } from '../../helpers/ipayHelper';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

function PopupHome() {

    const settingState = useSelector(state => state.settingReducer);
    const themeState = useSelector(state => state.themeReducer);
    const userState = useSelector(state => state.userReducer);
    const dispatch = useDispatch();

    const [configData, setConfigData] = useState({})
    const [linerLoading, setLinerLoading] = useState(false);
    const [publicScreenReady, setPublicScreenReady] = useState(false);
    const [errorScreenReady, setErrorScreenReady] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    let { sessionId } = useParams();

    useEffect(() => {

        onReadyApp();

        return () => {
            console.log('unmount POPUP Home component');
            window.removeEventListener('message', (event) =>  popupPaymentData(event));
        }

    },[]);

    const onReadyApp = () => {

        if(requestWindowType('IFRAME')){

            iframePaymentData();
        }
        else{

           
        }   
    }

    const sendPostDataToParent = (data) => {

        window.parent.postMessage(data, "*");
    }

    const iframePaymentData = () => {

        sendPostDataToParent({
            actionName : 'pageLoaded',
        });

        receiveData();
        
    }

    const receiveData = () => {

        window.addEventListener('message', (event) => popupPaymentData(event), false);
    }

    const popupPaymentData = async (event) => {

        if(event.data['options']!=undefined && event.data['options']['actionName']=='openPaymentGateway'){

            let getOriginalData = event.data;
            
            delete event.data['options']['actionName'];

            dispatch(SettingAction.showScreenLoader(true));

            let verifiedData = await apiRequest({
                method : 'POST',
                path : "auth/appPreferences",
                credentials : {
                    clientSecret : sessionId
                }
            },{}).catch(err => {
    
                dispatch(SettingAction.showScreenLoader(false));
    
                notify({
                    msg :'Something went wrong, #APVB1'
                });
    
                throw err;
            });

            dispatch(SettingAction.showScreenLoader(false));

            if(verifiedData.resp=='1'){

                let resData = verifiedData.data;

                setConfigData(getOriginalData['options']);

                //Update all config
                dispatch(ConfigAction.updateConfig(getOriginalData['options']));

                //Update Payment Action
                dispatch(SettingAction.updatePaymentActions(resData['appDetails']['paymentAction']));

                //Update Payer Login Status
                dispatch(UserAction.updatePayerLogin(resData['appDetails']['payerLoginType']));

                //check is user login
                if(resData['preferences']['customerDetails']!=undefined){

                    let customerData = {};
                    let isMobile = false;
                    let isEmail = false;

                    if(resData['preferences']['customerDetails']['email']!=undefined && resData['preferences']['customerDetails']['email']!=''){
                        isEmail = true;
                        customerData['emailId'] = resData['preferences']['customerDetails']['email'];
                    }

                    if(resData['preferences']['customerDetails']['mobile']!=undefined && resData['preferences']['customerDetails']['mobile']!=''){
                        isMobile = true;
                        customerData['mobile'] = resData['preferences']['customerDetails']['mobile'];
                    }

                    if(resData['preferences']['customerDetails']['name']!=undefined && resData['preferences']['customerDetails']['name']!=''){
                        
                        customerData['name'] = resData['preferences']['customerDetails']['name'];
                    }

                    if(resData['preferences']['customerDetails']['userToken']!=undefined && resData['preferences']['customerDetails']['userToken']!=''){
                        
                        customerData['userToken'] = resData['preferences']['customerDetails']['userToken'];
                    }

                    if(['NA'].includes(resData['appDetails']['payerLoginType'])){

                        dispatch(SettingAction.updateLoginScreen(false));
                    }
                    else {
    
                        if(isMobile && isEmail){
                            dispatch(SettingAction.updateLoginScreen(false));
                        }
                        else{
                            dispatch(SettingAction.updateLoginScreen(true));
                        }
                    }

                    //update user mobile and email
                    dispatch(CustomerAction.updateCustomer(customerData));

                }
                else{

                    if(['NA'].includes(resData['appDetails']['payerLoginType'])){

                        dispatch(SettingAction.updateLoginScreen(false));
                    }
                    else{
    
                        let existingContact = getFromLocalStorage('ipayPg.userProfile');
    
                        if(existingContact){
    
                            dispatch(CustomerAction.updateCustomer({
                                emailId : existingContact['email'],
                                mobile : existingContact['mobile'],
                            }));
    
                            dispatch(SettingAction.updateLoginScreen(false));
                        }
                        else{
                            dispatch(SettingAction.updateLoginScreen(true));
                        }
                    }
                }

                //Update User Details
                dispatch(UserAction.updateUserDetails({
                    displayName : (resData['preferences']['displayName']!=undefined && resData['preferences']['displayName']!='') ? resData['preferences']['displayName'] : resData['appDetails']['displayName'],
                    requestId : resData['appDetails']['requestId'],
                    payableAmount : resData['appDetails']['amount'],
                    envMode : (resData['appDetails']['mode']=='LIVE') ? resData['appDetails']['mode'] : 'TESTING',
                    logo : (resData['preferences']['logo']!=undefined && resData['preferences']['logo']!='') ? resData['preferences']['logo'] : resData['appDetails']['brandLogo'],
                }));

                //Update Payment Method
                dispatch(PaymentMethodAction.updatePaymentMethod(resData['paymentMode']));

                //Update Product Info
                dispatch(ProductAction.updateProductInfo({
                    name : resData['preferences']['productName'],
                    description : resData['preferences']['description'],
                    orderId : resData['preferences']['productOrderId'],
                }));

                //off screen loader
                dispatch(SettingAction.showScreenLoader(false));

                //Update Request Id
                dispatch(UserAction.updateUserJourneyId({
                    journeyId : sessionId
                }));

                //update theme color
                dispatch(ThemeAction.updateBrandColor(resData['appDetails']['colorCode']));

                //update background color
                dispatch(ThemeAction.updateBackgroundColor(resData['appDetails']['colorCode']));

                //update text color
                let getTextColorBasedOnBg = pickTextColorBasedOnBgColorAdvanced(resData['appDetails']['colorCode']);

                dispatch(ThemeAction.updateTextColor(getTextColorBasedOnBg));

                setPublicScreenReady(true);
                
            }
            else{

                notify({
                    msg : verifiedData.msg
                });
            }
        }
    }

    return (
        <div>
            {
                (linerLoading) ? <LinearProgress /> : '' 
            }

            {
                settingState.screenLoader ? 
                    <Backdrop
                        className='ipayBackdropTransparent'
                        sx={{ color: '#0033A1', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={settingState.screenLoader}
                        //onClick={closeScreenLoader}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop> : <></>
            }

            {
                (userState.envMode=='TESTING') ? <div className='envMode'><p>Test Mode</p></div> : ""
            }

            {
                publicScreenReady ? 
                    <div className="min-vh-100 d-flex flex-column justify-content-center">
                        <div className="row">
                            <div className='col-lg-4'></div>
                            <div className='col-lg-4'>
                                <MainScreen /> 
                            </div>
                            <div className='col-lg-4'></div>
                        </div>
                    </div> : <></>
            }

            {
                errorScreenReady ? 
                    <div className="min-vh-100 d-flex flex-column justify-content-center">
                        <div className="row">
                            <div className='col-lg-4'></div>
                            <div className='col-lg-4'>
                                <ErrorScreen errorMsg={errorMsg} /> 
                            </div>
                            <div className='col-lg-4'></div>
                        </div>
                    </div> : <></>
            }
           
        </div>
    );
}

export default PopupHome;
const initialThemeState = {
    
};

export default (state = initialThemeState, action) => {
    switch (action.type) {

        case 'UPDATE_CONFIG':

            const configData = action.payload;
            return { ...state, ...configData };

        default:
            return state;
    }
};
const initialState = {
    mobile: '',
    emailId: '',
    name : '',
    userToken: '',
};

export default (state = initialState, action) => {
    switch (action.type) {

        case 'UPDATE_CUSTOMER':

            const updateData = action.payload;
            return { ...state, ...updateData };

        case 'UPDATE_CUSTOMER_TOKEN':

            const tokenData = action.payload;
            return { ...state, ...tokenData };

        default:
            return state;
    }
};
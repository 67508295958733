import React, {useState, useEffect, useRef, useImperativeHandle, forwardRef} from 'react'
import './IpayWalletPayment.scss';
import { useDispatch, useSelector } from 'react-redux'
import { SettingAction, PaymentConfirmationAction } from '../../../stores/actions';
import {
    FormControl, 
    InputLabel, 
    CardContent, 
    InputAdornment, 
    OutlinedInput, 
    TextField,
    FormHelperText,
    Input,
    Checkbox,
    Dialog,
    DialogContent,
    Fade
} from '@mui/material';
import Paynow from '../../pay-now/Paynow';
import Constant from '../../../config/constant'
import _, { times } from 'lodash';
import { verifyRequest, notify } from '../../../helpers/ipayHelper';
import { apiRequest } from '../../../network/ApiClient';
import { convertAsNeed } from '../../../helpers/dateTimeHelper';


const IpayWalletPayment = (props, ref) => {

    const settingState = useSelector(state => state.settingReducer);
    const themeState = useSelector(state => state.themeReducer);
    const userState = useSelector(state => state.userReducer);
    const customerState = useSelector(state => state.customerReducer);
    const paymentConfirmationState = useSelector(state => state.paymentConfirmationReducer);
    const dispatch = useDispatch();

    //possible action name : MobileVerification,PinVerification,OtpVerification
    const [screenActionName, setScreenActionName] = useState('MobileVerification'); 

    //Screen List
    const [mobileNumberScreen, setMobileNumberScreen] = useState(true);
    const mobileNumberRef = useRef(null);
    const [twoFactorScreen, setTwoFactorScreen] = useState('PIN'); //PIN,OTP

    //Mobile Number Input
    const [registerMobileNumber, setRegisterMobileNumber] = useState('');
    const [isValidRegisterMobileNumber, setIsValidRegisterMobileNumber] = useState(null);
    const [markDisableRegisterMobileNumber, setMarkDisableRegisterMobileNumber] = useState(false);
    const mobileNumberInputRef = useRef(null);

    //Pin Input
    const [verifyPinNumber, setVerifyPinNumber] = useState('');
    const pinConfig = {
        pin1 : {
            value : '',
            originValue : '',
        },
        pin2 : {
            value : '',
            originValue : '',
        },
        pin3 : {
            value : '',
            originValue : '',
        },
        pin4 : {
            value : '',
            originValue : '',
        }
    }
    const [pinBoxItem, setPinBoxItem] = useState(pinConfig);
    const pin1Inputref = useRef(null);

    //OTP Input
    const [mobileOtpNumber, setMobileOtpNumber] = useState('');
    const [isValidMobileOtpNumber, setIsValidMobileOtpNumber] = useState(null);
    const resendOtpConfig = {
        totalTime : 30, //sec
        running : 30, //sec
    }
    const [otpTimeOperation, setOtpTimeOperation] = useState(resendOtpConfig);
    const catchOtpTimer = useRef(null)

    const [proceedBtnRunning, setProceedBtnRunning] = useState(false);

    const [verifyToken, setVerifyToken] = useState({
        token : ""
    });
 
    useEffect(() => {

        if(customerState.mobile!=undefined && customerState.mobile!=''){

            setRegisterMobileNumber(customerState.mobile);

            pin1Inputref.current.focus();
        }
        else{
            mobileNumberInputRef.current.focus();
        }


        return () => {
            
            closeScreenAction();
        }

    },[]);

    /**
     * Payment Retry Method
     */
    useEffect(() => {

        if(paymentConfirmationState.isRetryPayment && paymentConfirmationState.retryConfig.componentName  == IpayWalletPayment.displayName){

            if(paymentConfirmationState.retryConfig.actionName == 'PaymentRetry'){

                if(twoFactorScreen == 'PIN'){
                    resetPinScreen();
                }
                else{

                    _clearMobileOtpScreen();

                    _clearOtpTimer();

                    startOtpTimer();
                }

            }

            dispatch(PaymentConfirmationAction.paymentRetrySetup({
                clear : true
            }));
        }
    },[paymentConfirmationState.isRetryPayment]);

    const closeScreenAction = () => {

        setScreenActionName('MobileVerification');

        setIsValidRegisterMobileNumber(null);
        setRegisterMobileNumber('');
        setMarkDisableRegisterMobileNumber(false);

        setTwoFactorScreen('');

        resetPinScreen();

        _clearMobileOtpScreen();

        setVerifyToken({
            token : ""
        });
    }

    const backToMainPaymentScreen = () => {

        closeScreenAction();

        dispatch(SettingAction.updateIpayWalletPaymentScreen(false));
    }

    const isValidMobileNumber = (number,allowReturn=false) => {

        let regnum = new RegExp(Constant.validation.mobile);

        if (regnum.test(number)) {

            if(allowReturn){
                return true;
            }
            else{
                setIsValidRegisterMobileNumber(true);
            }
        }
        else{

            if(allowReturn){
                return false;
            }
            else{
                setIsValidRegisterMobileNumber(false);
            }   
        }
    }

    const _screenLoading = (visible) => {

        dispatch(SettingAction.showScreenLoader(visible));
    }

    const onChangeMobileNumber = async (event) => {

        let text = (event.target.value).trim();
        text = text.replace(/[^0-9]/g, '');

        let callToCheck = _.debounce(() => isValidMobileNumber(text),300);
        callToCheck();

        setRegisterMobileNumber(text);
    }

    const ipayWalletPaymentAction = () => {

        if(screenActionName == 'MobileVerification'){

            //onSubmitMobileNumber();
            //return false;
        }

        if(screenActionName == 'PinVerification'){
            //verifyPin();

            //First Valid Mobile if valid and pin shows then hit verifyPin
            onSubmitMobileNumber();
            return false;
        }

        if(screenActionName == 'OtpVerification'){
            submitOtp();
            return false;
        }
    }

    const onSubmitMobileNumber = async () => {

        _screenLoading(true);

        let params = {
            mobile : registerMobileNumber
        }

        let isValidRequest = verifyRequest({
            mobile : 'Mobile'
        },{
            mobile : Constant.validation.mobile
        },params);

        if(!isValidRequest.resp){

            _screenLoading(false);

            notify({
                msg : isValidRequest.msg
            });

            return false;
        }

        let reqData = {
            mobile : params['mobile'],
            mode : 'IPIN',
        };

        if(customerState.userToken!=undefined && customerState.userToken!=''){
            reqData['userToken'] = customerState.userToken;
        }

        validateUsername(reqData);
    }

    const changeVerifyMethod = async () => {

        if(twoFactorScreen=='PIN'){

            _clearOtpTimer();
            resetPinScreen();
            setTwoFactorScreen('OTP')
            setScreenActionName('OtpVerification');
            startOtpTimer();

            let reqData = {
                mobile : registerMobileNumber,
                mode : 'OTP',
            };
    
            if(customerState.userToken!=undefined && customerState.userToken!=''){
                reqData['userToken'] = customerState.userToken;
            }
    
            validateUsername(reqData);
        }
        else{

            _clearOtpTimer();
            _clearMobileOtpScreen();
            setTwoFactorScreen('PIN')
            setScreenActionName('PinVerification');

            let reqData = {
                mobile : registerMobileNumber,
                mode : 'IPIN',
            };
    
            if(customerState.userToken!=undefined && customerState.userToken!=''){
                reqData['userToken'] = customerState.userToken;
            }

            pin1Inputref.current.focus();
    
            //validateUsername(reqData);
            
        }
    }

    const resetPinScreen = () => {

        setVerifyPinNumber('');
        setPinBoxItem(pinConfig);

        if(document.getElementById('pinBox1')){
            document.getElementById('pinBox1').removeAttribute('readOnly');
        }

        if(document.getElementById('pinBox2')){
            document.getElementById('pinBox2').removeAttribute('readOnly');
        }
        
        if(document.getElementById('pinBox3')){
            document.getElementById('pinBox3').removeAttribute('readOnly');
        }

        if(document.getElementById('pinBox4')){
            document.getElementById('pinBox4').removeAttribute('readOnly');
        }
    }

    const onChangePin = async (event,currentId, nextId) => {

        let text = (event.target.value).trim();
        text = text.replace(/[^0-9]/g, '');

        if(text!=''){

            if(currentId=='pinBox1'){
                pinBoxItem['pin1']['originValue'] = text;
                pinBoxItem['pin1']['value'] = "⬤";
                setPinBoxItem({...pinBoxItem});

                document.getElementById(currentId).setAttribute('readOnly',true);

                document.getElementById(nextId).removeAttribute('readOnly');
                document.getElementById(nextId).focus();
                
            }

            if(currentId=='pinBox2'){
                pinBoxItem['pin2']['originValue'] = text;
                pinBoxItem['pin2']['value'] = "⬤";
                setPinBoxItem({...pinBoxItem});

                document.getElementById(currentId).setAttribute('readOnly',true);

                document.getElementById(nextId).removeAttribute('readOnly');
                document.getElementById(nextId).focus();
                
            }

            if(currentId=='pinBox3'){
                pinBoxItem['pin3']['originValue'] = text;
                pinBoxItem['pin3']['value'] = "⬤";
                setPinBoxItem({...pinBoxItem});

                document.getElementById(currentId).setAttribute('readOnly',true);

                document.getElementById(nextId).removeAttribute('readOnly');
                document.getElementById(nextId).focus();
                
            }

            if(currentId=='pinBox4'){
                pinBoxItem['pin4']['originValue'] = text;
                pinBoxItem['pin4']['value'] = "⬤";
                setPinBoxItem({...pinBoxItem});

                //verifyPin();

                onSubmitMobileNumber();
                
            }
        }
    }

    const clearPinBox = async (event,currentId, nextId) => {

        if(event.key=="Backspace" || event.keyCode=='8'){
            
            if(currentId=='pinBox4'){
                pinBoxItem['pin4']['originValue'] = '';
                pinBoxItem['pin4']['value'] = "";
                pinBoxItem['pin3']['originValue'] = '';
                pinBoxItem['pin3']['value'] = "";
                setPinBoxItem({...pinBoxItem});

                document.getElementById(currentId).setAttribute('readOnly',true);

                document.getElementById(nextId).removeAttribute('readOnly');
                document.getElementById(nextId).focus();
            }

            if(currentId=='pinBox3'){
                pinBoxItem['pin2']['originValue'] = '';
                pinBoxItem['pin2']['value'] = "";
                setPinBoxItem({...pinBoxItem});

                document.getElementById(currentId).setAttribute('readOnly',true);

                document.getElementById(nextId).removeAttribute('readOnly');
                document.getElementById(nextId).focus();
            }

            if(currentId=='pinBox2'){
                pinBoxItem['pin1']['originValue'] = '';
                pinBoxItem['pin1']['value'] = "";
                setPinBoxItem({...pinBoxItem});

                document.getElementById(currentId).setAttribute('readOnly',true);

                document.getElementById(nextId).removeAttribute('readOnly');
                document.getElementById(nextId).focus();
            }
        }
    }

    const verifyPin = async (token='') => {

        _screenLoading(true);

        let getPin = pinBoxItem.pin1.originValue + pinBoxItem.pin2.originValue + pinBoxItem.pin3.originValue + pinBoxItem.pin4.originValue;

        let params = {
            mobile : registerMobileNumber,
            possibleValue : getPin,
            token : token,
        }

        let isValidRequest = verifyRequest({
            mobile : 'Mobile',
            possibleValue : 'iPIN',
            token : 'Token',
        },{
            mobile : Constant.validation.mobile,
            possibleValue : Constant.validation.ipin,
        },params);

        if(!isValidRequest.resp){

            _screenLoading(false);

            notify({
                msg : isValidRequest.msg
            });

            return false;
        }

        verifyPayment(params);
    }

    const _clearMobileOtpScreen = () => {

        setMobileOtpNumber('');

        setIsValidMobileOtpNumber(null);

        setOtpTimeOperation(resendOtpConfig);
    }

    const isValidMobileOtp = (number,allowReturn=false) => {

        let regnum = new RegExp(Constant.validation.otp);

        if (regnum.test(number)) {

            if(allowReturn){
                return true;
            }
            else{
                setIsValidMobileOtpNumber(true);
            }
        }
        else{

            if(allowReturn){
                return false;
            }
            else{
                setIsValidMobileOtpNumber(false);
            }   
        }
    }

    const onChangeMobileOtpNumber = (event) => {

        let text = (event.target.value).trim();
        text = text.replace(/[^0-9]/g, '');

        let callToCheck = _.debounce(() => isValidMobileOtp(text),300);
        callToCheck();

        setMobileOtpNumber(text);
    }

    const startOtpTimer = () => {

        catchOtpTimer.current = setInterval(() => {

            if(otpTimeOperation['running'] == 0 ){
                //alert("Operation Timeout");
                clearInterval(catchOtpTimer.current);
                catchOtpTimer.current = null;
                return false;
            }
    
            otpTimeOperation['running'] -=1;
            setOtpTimeOperation({...otpTimeOperation});

        },1000);
    }

    const _clearOtpTimer = () => {

        clearInterval(catchOtpTimer.current);
        catchOtpTimer.current = null;
        otpTimeOperation['running'] = otpTimeOperation['totalTime'];
        setOtpTimeOperation({...otpTimeOperation});
    }

    const onSubmitResendOtp = () => {

        let reqData = {
            mobile : registerMobileNumber,
            mode : 'OTP',
        };

        if(customerState.userToken!=undefined && customerState.userToken!=''){
            reqData['userToken'] = customerState.userToken;
        }

        validateUsername(reqData);

        _clearOtpTimer();

        startOtpTimer();
    }

    const submitOtp = async (token='') => {
        
        _screenLoading(true);

        let params = {
            mobile : registerMobileNumber,
            possibleValue : mobileOtpNumber,
            token : (token!='') ? token : verifyToken['token'],
        }

        let isValidRequest = verifyRequest({
            mobile : 'Mobile',
            possibleValue : 'OTP',
            token : 'Token',
        },{
            mobile : Constant.validation.mobile,
            possibleValue : Constant.validation.otp,
        },params);

        if(!isValidRequest.resp){

            _screenLoading(false);

            notify({
                msg : isValidRequest.msg
            });

            return false;
        }

        verifyPayment(params);
    }

    const validateUsername = async (params) => {

        setVerifyToken({
            token : ""
        });

        let validateData = await apiRequest({
            method : 'POST',
            path : "pay/ipay/validate",
            credentials : {
                clientSecret : userState.journeyId
            }
        },params).catch(err => {

            _screenLoading(false);

            notify({
                msg :'Something went wrong, #VIEW-IWPVUD1'
            });

            throw err;
        });

        _screenLoading(false);

        if(validateData.resp=='1'){

            setMarkDisableRegisterMobileNumber(true);

            setVerifyToken(prevState => {
                return {
                    ...prevState,
                    token: validateData.data.token
                };
            });

            if(params['mode']=='OTP'){

                setTwoFactorScreen('OTP');
                setScreenActionName('OtpVerification');

                notify({
                    type : 'success',
                    msg : validateData.msg
                });

                //submitOtp(validateData.data.token)
            }
            else{

                //setTwoFactorScreen('PIN');
                //setScreenActionName('PinVerification'); 

                verifyPin(validateData.data.token)
            }
        }
        else{

            notify({
                msg : validateData.msg
            });
        }
        
    }

    const verifyPayment = async (params) => {

        let verifyPaymentData = await apiRequest({
            method : 'POST',
            path : "pay/ipay/verify",
            setLocation : 1,
            credentials : {
                clientSecret : userState.journeyId
            }
        },params).catch(err => {

            _screenLoading(false);

            notify({
                msg :'Something went wrong, #VIEW-IWPVP1'
            });

            throw err;
        });

        _screenLoading(false);

        _clearMobileOtpScreen();

        resetPinScreen();

        if(verifyPaymentData.resp=='1'){

            dispatch(PaymentConfirmationAction.showPaymentConfirmation({
                status : 'SUCCESS',
                description : 'Transaction Successful',
                paymentData : {
                    paymentMode: verifyPaymentData.data.paymentMode,
                    paymentId: verifyPaymentData.data.transactionId,
                    paymentCreated: verifyPaymentData.data.paymentCreated,
                },
                actionName : 'RedirectToSource',
                redirectToSource : verifyPaymentData.data.sourceInfo.redirectTo,
                redirectToSourceData : verifyPaymentData.data?.sourceInfo?.redirectData,
            }));
        }
        else if(verifyPaymentData.resp=='2'){

            dispatch(PaymentConfirmationAction.showPaymentConfirmation({
                status : 'PENDING',
                description : 'Transaction Under Process',
                paymentData : {
                    paymentMode: verifyPaymentData.data.paymentMode,
                    paymentId: verifyPaymentData.data.transactionId,
                    paymentCreated: verifyPaymentData.data.paymentCreated,
                },
                actionName : 'RedirectToSource',
                redirectToSource : verifyPaymentData.data.sourceInfo.redirectTo,
                redirectToSourceData : verifyPaymentData.data?.sourceInfo?.redirectData,
            }));
        }
        else{

            let confirmData = {
                status : 'FAILED',
                description : verifyPaymentData.msg,
                componentName : '', //IpayWalletPayment.displayName,
                actionName : 'RedirectToSource' //'PaymentRetry'
            };

            if(verifyPaymentData.actCode!=undefined && verifyPaymentData.actCode=='RedirectToSource'){

                confirmData['componentName'] = '';
                confirmData['actionName'] =  'RedirectToSource';

                if(verifyPaymentData.data!=undefined && verifyPaymentData.data!=null){

                    if(verifyPaymentData.data.redirectTo!=undefined){
                        confirmData['redirectToSource'] = verifyPaymentData.data.redirectTo;
                    }
    
                    if(verifyPaymentData.data.redirectData!=undefined){
                        confirmData['redirectToSourceData'] = verifyPaymentData.data.redirectData;
                    }
                }   
            }

            dispatch(PaymentConfirmationAction.showPaymentConfirmation(confirmData));
        }
        
    }

    const myTest = () => {

        //console.log('sdfdfsgfdgdfgdfgdgdfdfg');

    }

    useImperativeHandle(ref, () => ({
        test: () => { myTest() },
    }))

    return (

        <div className='ipaywallet-payment-main-section'>
            <CardContent>
                <div className='d-flex flex-row align-items-center mb-4'> 
                    <div className='mark-close pointer' onClick={() => backToMainPaymentScreen()}>
                        <i className="material-icons ">navigate_before</i>
                    </div> 
                    <p className='font-size-14 font-weight-600 text-dark mb-2'>Instantpay</p>
                </div>
                <div className='mb-3'>

                    {
                        mobileNumberScreen ?
                            <div className='mb-3'>
                                <FormControl fullWidth variant="outlined" className='ipayInput'>
                                    <InputLabel>Registerd Mobile Number</InputLabel>
                                    <OutlinedInput
                                        type={'text'}
                                        label="Registerd Mobile Number"
                                        inputRef={mobileNumberInputRef}
                                        inputProps={{
                                            autoCapitalize : 'off',
                                            autoCorrect : 'off',
                                            spellCheck : 'off',
                                            maxLength : '10',
                                            //autoFocus : true
                                        }}
                                        disabled={markDisableRegisterMobileNumber}
                                        value={registerMobileNumber}
                                        onChange={(event) => onChangeMobileNumber(event)}
                                        error={( isValidRegisterMobileNumber!=null && !isValidRegisterMobileNumber) ? true : false }
                                        onKeyDown={(e) => (e.key === 'Enter' ? ipayWalletPaymentAction() : null)}
                                        //.onSubmit={() => alert("ddfdf")}
                                    />
                                    <FormHelperText className='text-danger'>{isValidRegisterMobileNumber!=null && !isValidRegisterMobileNumber  ? 'Invalid Mobile Number' : ''}</FormHelperText>
                                </FormControl> 
                            </div> : <></>
                    }

                    {
                        twoFactorScreen == 'PIN' ?
                            <div className='mb-3'>
                                <p>Please enter your 4 digit iPIN to confirm</p>
                                <div className='d-flex flex-row justify-content-between'>
                                    <TextField
                                        inputRef={pin1Inputref}
                                        id="pinBox1" 
                                        label="" 
                                        variant="outlined" 
                                        className='pinBox ipayInput' 
                                        inputProps={{
                                            maxLength : 1,
                                        }}
                                        onChange={(event) => onChangePin(event,'pinBox1','pinBox2')}
                                        value={pinBoxItem['pin1']['value']}
                                        onKeyDown={(event) => clearPinBox(event,'pinBox1','') }
                                    />

                                    <TextField 
                                        id="pinBox2" 
                                        label="" 
                                        variant="outlined" 
                                        className='pinBox ipayInput'
                                        inputProps={{
                                            readOnly : true
                                        }}
                                        onChange={(event) => onChangePin(event,'pinBox2','pinBox3')}
                                        value={pinBoxItem['pin2']['value']}
                                        onKeyDown={(event) => clearPinBox(event,'pinBox2','pinBox1') }
                                    />

                                    <TextField 
                                        id="pinBox3" 
                                        label="" 
                                        variant="outlined" 
                                        className='pinBox ipayInput'
                                        inputProps={{
                                            readOnly : true
                                        }}
                                        onChange={(event) => onChangePin(event,'pinBox3','pinBox4')}
                                        value={pinBoxItem['pin3']['value']}
                                        onKeyDown={(event) => clearPinBox(event,'pinBox3','pinBox2') }
                                    />

                                    <TextField 
                                        id="pinBox4" 
                                        label="" 
                                        variant="outlined" 
                                        className='pinBox ipayInput'
                                        inputProps={{
                                            readOnly : true
                                        }}
                                        onChange={(event) => onChangePin(event,'pinBox4','')}
                                        value={pinBoxItem['pin4']['value']}
                                        onKeyDown={(event) => clearPinBox(event,'pinBox4','pinBox3') }
                                    />
                                </div>
                                
                            </div> : <></>
                    }

                    {
                        twoFactorScreen == 'OTP' ? 
                            <div className='mb-3'>
                                <p>Enter your OTP to confirm </p>
                                <FormControl fullWidth variant="outlined" className='ipayInput'>
                                    <InputLabel>Mobile OTP</InputLabel>
                                    <OutlinedInput
                                        type={'text'}
                                        label="Mobile OTP"
                                        inputProps={{
                                            autoCapitalize : 'off',
                                            autoCorrect : 'off',
                                            spellCheck : 'off',
                                            maxLength : '6',
                                            autoFocus : true
                                        }}
                                        value={mobileOtpNumber}
                                        onChange={(event) => onChangeMobileOtpNumber(event)}
                                        error={( isValidMobileOtpNumber!=null && !isValidMobileOtpNumber) ? true : false }
                                        onKeyDown={(e) => (e.key === 'Enter' ? ipayWalletPaymentAction() : null)}
                                    />
                                    <div className='d-flex justify-content-between'>
                                        <FormHelperText className='text-danger'>{isValidMobileOtpNumber!=null && !isValidMobileOtpNumber  ? 'Invalid Mobile OTP' : ''}</FormHelperText>
                                        <FormHelperText className='text-primary pointer' onClick={() => (otpTimeOperation['running'] == 0) ? onSubmitResendOtp() : null }> {otpTimeOperation['running'] == 0 ? 'Resend OTP?' : 'Resend OTP in ' +otpTimeOperation['running']    } </FormHelperText>
                                    </div>
                                </FormControl> 
                            </div> : <></>

                    }

                    {
                        twoFactorScreen!='' ? 
                        <p className='text-primary font-size-12 text-center hover-underline-primary'> <span className='pointer' onClick={() => changeVerifyMethod()}> Use { twoFactorScreen == 'PIN' ? 'OTP' : 'iPIN' } to Verify? </span>  </p>  : <></>

                    }

                </div>
                
            </CardContent>

            {/** Paynow Section */}
            <Paynow actionFired={() => ipayWalletPaymentAction()} running={proceedBtnRunning}  />
            
        </div>
    )

}

IpayWalletPayment.displayName = "IpayWalletPayment";
export default forwardRef(IpayWalletPayment);
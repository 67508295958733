const typeOfCards = {
    visa : {
        block : [4, 4, 4, 4], //Card Format
        name : 'VISA',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/visa.svg',
        // starts with 4; 16 digits
        re : /^4\d{0,15}/,
        length : 16,
    },
    mastercard : {
        block : [4, 4, 4, 4],
        name : 'MASTERCARD',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/master_card.svg',
        // starts with 51-55/2221–2720; 16 digits
        re : /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}/,
        length : 16,
    },
    maestro : {
        block : [4, 4, 4, 4],
        name : 'MAESTRO',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/maestro.svg',
        // starts with 50/56-58/6304/67; 16 digits
        re : /^(?:5[0678]\d{0,2}|6304|67\d{0,2})\d{0,12}/,
        length : 16,
    },
    rupay : {
        block : [4, 4, 4, 4],
        name : 'RUPAY',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/rupay.svg',
        // starts with 787878; 16 digits
        re : /^787878/,
        length : 16,
    },
    rupay1 : {
        block : [4, 4, 4, 4],
        name : 'RUPAY',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/rupay.svg',
        // starts with 60, 65, 81, 82, 508; 16 digits
        re : /^(508[5-9]|60(80(0|)[^0]|8[1-4]|8500|698[5-9]|699|7[^9]|79[0-7]|798[0-4])|65(2(1[5-9]|[2-9])|30|31[0-4])|817[2-9]|81[89]|820[01])/,
        length : 16,
    },
    amex : {
        block : [4, 6, 5],
        name : 'AMEX',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/amex.svg',
        // starts with 34/37; 15 digits
        re : /^3[47]\d{0,13}/,
        length : 15,
    },
    discover : {
        block : [4, 4, 4, 4],
        name : 'DISCOVER',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/discover.svg',
        // starts with 6011/65/644-649; 16 digits
        re : /^(?:6011|65\d{0,2}|64[4-9]\d?)\d{0,12}/,
        length : 16,
    },
    uatp : {
        block : [4, 5, 6],
        name : 'UATP',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/uatp.svg',
        // starts with 1; 15 digits, not starts with 1800 (jcb card)
        re: /^(?!1800)1\d{0,14}/,
        length : 15,
    },
    diners : {
        block : [4, 6, 4],
        name : 'DINERS',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/diners.svg',
        // starts with 300-305/309 or 36/38/39; 14 digits
        re: /^3(?:0([0-5]|9)|[689]\d?)\d{0,11}/,
        length : 14,
    },
    dankort : {
        block : [4, 4, 4, 4],
        name : 'DANKORT',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/dankort.svg',
        // starts with 5019/4175/4571; 16 digits
        re: /^(5019|4175|4571)\d{0,12}/,
        length : 16,
    },
    instapayment : {
        block : [4, 4, 4, 4],
        name : 'INSTAPAYMENT',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/common.svg',
        // starts with 637-639; 16 digits
        re: /^63[7-9]\d{0,13}/,
        length : 16,
    },
    jcb15 : {
        block : [4, 6, 5],
        name : 'JCB15',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/jcb.svg',
        // starts with 2131/1800; 15 digits
        re: /^(?:2131|1800)\d{0,11}/,
        length : 15,
    },
    jcb : {
        block : [4, 4, 4, 4],
        name : 'JCB',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/jcb.svg',
        // starts with 2131/1800/35; 16 digits
        re: /^(?:35\d{0,2})\d{0,12}/,
        length : 16,
    },
    mir : {
        block : [4, 4, 4, 4],
        name : 'MIR',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/mir.svg',
        // starts with 22; 16 digits
        re: /^220[0-4]\d{0,12}/,
        length : 16,
    },
    unionPay : {
        block : [4, 4, 4, 4],
        name : 'UNIONPAY',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/unionpay.svg',
        // starts with 62/81; 16 digits
        re: /^(62|81)\d{0,14}/,
        length : 16,
    },
    bajaj : {
        block : [4, 4, 4, 4],
        name : 'BAJAJ',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/bajaj.svg',
        // starts with 203040; 16 digits
        re: /^203040/,
        length : 16,
    },
    general: {
        block : [4, 4, 4, 4],
        name : 'UNKNOWN',
        img : process.env.REACT_APP_CORE_STATIC_URL +'assets/cards/credit/common.svg',
        length : 16,
    },
}

export const validateCardNumber = (passedCardNumber) => {

    return new Promise((resolve,reject) => {

        let output = {
            status : 'INVALID',//INVALID,VALID
            desc : '',
            img : '',
            cardName : '',
            cardBlock : '',
            cardNumberLength : ''
        }

        passedCardNumber = passedCardNumber.replace(/[^\d]/g, '');

        let inputValue = passedCardNumber;
        inputValue = inputValue.replace(/\s/g, '');
        //console.log('inputValue:',inputValue);

        if(inputValue=='' || inputValue==null){
            return resolve(output);
        }

        let cardType = identifyCardTypeWithNumber(inputValue,false);

        output['cardBlock'] = cardType['block'];
        output['img'] = cardType['img'];
        output['cardName'] = cardType['name'];
        output['cardNumberLength'] = cardType['length'];

        if(inputValue.length!=cardType['length']){
            //console.log('dddd',inputValue.length, inputValue);
            //output['desc'] = "Card Number not matching with Card Type";
        }
        else{
            
            let isValidCard = validateCardCheckSum(inputValue);
    
            //console.log({isValidCard});
            if(isValidCard){
                output['status'] = 'VALID';
                output['desc'] = "";
            }
            else{
                output['desc'] = "Invalid Card Number";
            }
        }
    
        return resolve(output);

    });
}

const identifyCardTypeWithNumber = (value, strictMode) => {

    value = value.toString();

    /* var blocks = this.blocks,
        re = this.re; */
    let getAllCard = typeOfCards;

    // Some credit card can have up to 19 digits number.
    // Set strictMode to true will remove the 16 max-length restrain,
    // however, I never found any website validate card number like
    // this, hence probably you don't want to enable this option.
    strictMode = !!strictMode;

    for (var key in getAllCard) {

        /* if (re[key].test(value)) {
            var matchedBlocks = blocks[key];
            return {
                type: key,
                blocks: strictMode ? this.makeCardStrictBlocks(matchedBlocks) : matchedBlocks
            };
        } */

        if(getAllCard[key]['re']!=undefined){

            if(getAllCard[key]['re'].test(value)){
                let matchedBlocks = getAllCard[key]['block'];
                return getAllCard[key];
            }
        }

        /* if(key['re'].test(value)){
            let matchedBlocks = key['block'];
            return key;
        } */
    }

    return typeOfCards['general'];
}

const makeCardStrictBlocks = (block) => {
    var total = block.reduce(function (prev, current) {
      return prev + current;
    }, 0);

    return block.concat(19 - total);
}

const validateCardCheckSum = (crdNo) => {

    crdNo = crdNo.replace(/\s/g, '');

    let LuhnDigit = parseInt(crdNo.substring(crdNo.length-1, crdNo.length));
    let LuhnLess = crdNo.substring(0,crdNo.length-1);

    if (calculateCardCheckSum(LuhnLess) == parseInt(LuhnDigit)) {
        return true;
    }

    return false;
}

const calculateCardCheckSum = (cdNo) => {

    let sum = 0;

    for (let i=0; i<cdNo.length; i++ ) {
        sum += parseInt(cdNo.substring(i,i+1));
    }

    let delta = new Array (0, 1, 2, 3, 4, -4, -3, -2, -1, 0);
    for (let i=cdNo.length-1; i>=0; i-=2 ) {
        let deltaIndex = parseInt(cdNo.substring(i, i + 1));
        let deltaValue = delta[deltaIndex];
        sum += deltaValue;
    }

    let mod10 = sum % 10;
    mod10 = 10 - mod10;

    if (mod10 == 10) {
        mod10 = 0;
    }

    return mod10;
}
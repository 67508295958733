import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-toastify/dist/ReactToastify.min.css';
import './global.scss';
import App from './App';
import { Provider } from 'react-redux';
import store from './stores/store';
import { BrowserRouter } from 'react-router-dom';

//console.log('Application running on environment : ' + process.env.REACT_APP_ENV);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter> 
    </Provider>
);

{/* <React.StrictMode>
<Provider store={store}>
    <App />
</Provider>
</React.StrictMode> */}
const initialUserState = {
    envMode : '', // default : TESTING, LIVE
    displayName : '', //'Business Name',
    requestId : '',
    logo : '', //https://static.instantpay.in/assets/web/footer/footer_icon.svg
    payableAmount : '',
    journeyId : '',
    payerLogin : 'MANDATORY',
};


export default (state = initialUserState, action) => {
    switch (action.type) {

        case 'UPDATE_USER_DATA':

            const userData = action.payload;
            return { ...state, ...userData };

        case 'UPDATE_ENVIRONMENT_MODE':

            const envMode = action.payload;
            return { ...state, ...envMode };

        case 'UPDATE_JOURNEY_ID':

            const journeyId = action.payload;
            return { ...state, ...journeyId };

        case 'UPDATE_PAYER_LOGIN':

            const payerLogin = action.payload;
            return { ...state, ...payerLogin };
            
        default:
            return state;
    }
};
import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import IpayLogo from '../../assets/ipay_logo.svg';
import { indianCurrency } from '../../helpers/ipayHelper'
import { SettingAction } from '../../stores/actions'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Slide, Fade } from '@mui/material';


const TransitionUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export default function Paynow(props) {

    const settingState = useSelector(state => state.settingReducer);
    const themeState = useSelector(state => state.themeReducer);
    const userState = useSelector(state => state.userReducer);
    const productState = useSelector(state => state.productReducer);
    const dispatch = useDispatch();

    const [summaryDetailsVisible, setSummaryDetailsVisible] = useState(false);

    const showPaymentSummary = () => {
        setSummaryDetailsVisible(true);    
    }

    const closePaymentSummary = () => {
        setSummaryDetailsVisible(false); 
    }

    return (
        <div>
            <CardContent id='bottomViewForPayNow' style={{height: settingState.bottomViewForPayNowHeight}} className={`payment-section ${settingState.visiblePaymentSection ? '' : 'hidden'}`}>
                <div className='d-flex flex-row justify-content-between mt-2'>
                    <div className='d-flex flex-column'>
                        <span className='text-dark font-size-14 font-weight-500 paynow-title'>Pay Now</span>
                        <span className='text-lightdark font-size-10 font-weight-400'>Secured By&nbsp;&nbsp;<img src={IpayLogo} /> </span>
                    </div>
                    <div className='d-flex flex-column align-items-end'>
                        <span className='text-dark font-size-16 font-weight-700'>{ indianCurrency(userState.payableAmount) }</span>
                        <span className='text-primary font-size-12 font-weight-400 pointer' onClick={() => showPaymentSummary()}>View Details</span>
                    </div>
                </div>
                <div className='d-flex justify-content-center align-items-center mt-4'>
                    <Button 
                        disabled={props.running}
                        onClick={() => props.actionFired()}
                        variant="contained" 
                        size="large" 
                        className={`width-90p ${props.extraClassName}`}
                        style={{
                            backgroundColor: themeState.bgColor,
                            color: themeState.textColor,
                            opacity: (props.running) ? 0.5 : 1
                        }}
                    >Proceed</Button>
                </div>
            </CardContent>
            
            {/** Order Summary Screen */}
            <Dialog
                className='ipayModalBottom'
                open={summaryDetailsVisible}
                keepMounted
                onClose={() => closePaymentSummary() }
                container={() => document.getElementById('bottomViewForPayNow')}
                TransitionComponent={Fade}
            >
                <DialogContent>
                    
                    <div className='d-flex flex-row justify-content-between mb-4'>
                        <span className='font-size-16 font-weight-700 text-dark'>Order Summary</span>
                        <div className='mark-close pointer' onClick={() => closePaymentSummary()}>
                            <i className="material-icons">close</i>
                        </div>
                    </div>
                    {
                       (productState.productName!=undefined && productState.productName!='') ? 
                            <div className='d-flex flex-row justify-content-between pb-2' style={{borderBottom: '1px dotted #ccc'}}>
                                <span className='font-size-14 font-weight-400 text-lightdark'>{productState.productName}</span>
                                <span className='text-dark font-weight-500'>{ indianCurrency(userState.payableAmount) }</span>
                            </div> :<></>
                    }
                    
                    <div className='d-flex flex-row justify-content-between pt-2'>
                        <span className='font-size-14 font-weight-500 text-dark'>Total Amount</span>
                        <span className='text-dark font-weight-500'>{ indianCurrency(userState.payableAmount) }</span>
                    </div>
                    
                </DialogContent>
            </Dialog>
            
        </div>
    )
}
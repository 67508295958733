/**
 * 
 * @param {string} colorCode //#00000
 * @returns 
 */
const updateTextColor = (colorCode) => {

    return {
        type: 'UPDATE_TEXT_COLOR',
        payload: {
            textColor : colorCode
        }
    }
}


/**
 * 
 * @param {string} colorCode //#00000
 * @returns 
 */
const updateBackgroundColor = (colorCode) => {

    return {
        type: 'UPDATE_BG_COLOR',
        payload: {
            bgColor : colorCode
        }
    }
}

/**
 * 
 * @param {string} colorCode //#00000
 * @returns 
 */
const updateBrandColor = (colorCode) => {

    return {
        type: 'UPDATE_BRAND_COLOR',
        payload: {
            brandColor : colorCode
        }
    }
}

export default {
    updateTextColor,
    updateBackgroundColor,
    updateBrandColor
}
const initialState = {
    visible : '',
    status : '', //SUCCESS, PENDING, FAILED
    description : '',
    componentName : '',
    actionName : '',
    paymentData : {},
    extraData : {},
    allowRawRedirect : false,
    redirectToSource : '',
    redirectToSourceData : {},
    isRetryPayment : false,
    retryConfig : {},
};

export default (state = initialState, action) => {
    switch (action.type) {

        case 'UPDATE_PAYMENT_CONFIRMATION':

            const paymentData = action.payload;
            return { ...state, ...paymentData };

        case 'UPDATE_PAYMENT_RETRY':

            const retryData = action.payload;
            return { ...state, ...retryData };

        default:
            return state;
    }
};
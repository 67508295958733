/**
 * 
 * @param {object} data
 * @returns 
 */
const updateCustomer = (data) => {

    let defaultData = {};

    if(data['mobile']!=undefined && data['mobile']!=''){
        defaultData['mobile'] = data['mobile'];
    }

    if(data['emailId']!=undefined && data['emailId']!=''){
        defaultData['emailId'] = data['emailId'];
    }

    if(data['name']!=undefined && data['name']!=''){
        defaultData['name'] = data['name'];
    }

    if(data['userToken']!=undefined && data['userToken']!=''){
        defaultData['userToken'] = data['userToken'];
    }

    return {
        type: 'UPDATE_CUSTOMER',
        payload: defaultData
    }
}


const updateUserToken = (token) => {

    return {
        type: 'UPDATE_CUSTOMER_TOKEN',
        payload: {
            userToken: token
        }
    }
}

export default {
    updateCustomer,
    updateUserToken
}
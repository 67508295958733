
/**
 * 
 * @param {boolean} visible //true,false
 * @returns 
 */
const updateVisiblePaymentSection = (visible) => {

    return {
        type: 'UPDATE_VISIBLE_PAYMENT_SECTION',
        payload: {
            visiblePaymentSection : visible
        }
    }
}


/**
 * 
 * @param {boolean} visible //true,false
 * visible : true,false
 * @returns 
 */
const updateLoginScreen = (visible) => {

    let btmSection = visible ? false : true; 

    return {
        type: 'UPDATE_LOGIN_SCREEN',
        payload: {
            loginScreenVisible : visible,
            paymentScreenVisible : btmSection
        }
    }
}

/**
 * 
 * @param {boolean} visible //true,false
 * visible : true,false
 * @returns 
 */
const updateCardPaymentScreen = (visible) => {

    let btmSection = visible ? false : true; 

    return {
        type: 'UPDATE_CARD_PAYMENT_SCREEN',
        payload: {
            cardPaymentScreenVisible : visible,
            paymentScreenVisible : btmSection
        }
    }
}


/**
 * 
 * @param {boolean} visible //true,false
 * visible : true,false
 * @returns 
 */
const updateWalletPaymentScreen = (visible) => {

    let btmSection = visible ? false : true; 

    return {
        type: 'UPDATE_WALLET_PAYMENT_SCREEN',
        payload: {
            walletPaymentScreenVisible : visible,
            paymentScreenVisible : btmSection
        }
    }
}

/**
 * 
 * @param {boolean} visible //true,false
 * visible : true,false
 * @returns 
 */
const updateNetbankingPaymentScreen = (visible) => {

    let btmSection = visible ? false : true; 

    return {
        type: 'UPDATE_NETBANKING_PAYMENT_SCREEN',
        payload: {
            netbankingPaymentScreenVisible : visible,
            paymentScreenVisible : btmSection
        }
    }
}


/**
 * 
 * @param {boolean} visible //true,false
 * visible : true,false
 * @returns 
 */
const updateIpayWalletPaymentScreen = (visible) => {

    let btmSection = visible ? false : true; 

    return {
        type: 'UPDATE_IPAY_WALLET_PAYMENT_SCREEN',
        payload: {
            ipayWalletPaymentScreenVisible : visible,
            paymentScreenVisible : btmSection
        }
    }
}

/**
 * 
 * @param {string} mode //POPUP,WINDOW
 * @returns 
 */
const updatePaymentActions = (mode) => {

    return {
        type: 'UPDATE_PAYMENT_ACTIONS',
        payload: {
            paymentAction : mode
        }
    }
}

/**
 * 
 * @param {boolean} visible //POPUP,WINDOW
 * @returns 
 */
const showScreenLoader = (visible) => {

    return {
        type: 'UPDATE_SCREEN_LOADER',
        payload: {
            screenLoader : visible
        }
    }
}



export default {
    updateVisiblePaymentSection,
    updateLoginScreen,
    updateCardPaymentScreen,
    updateWalletPaymentScreen,
    updateNetbankingPaymentScreen,
    updateIpayWalletPaymentScreen,
    updatePaymentActions,
    showScreenLoader,
}